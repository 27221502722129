import React from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { MainBtn } from "../../components/button";

export const UsersArr = [
	{
		name: "Students",
		path: "student",
	},
	{
		name: "Company",
		path: "company",
	},
	{
		name: "Institution",
		path: "institution",
	},
];
const Plan = () => {
	let navigate = useNavigate();

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Users"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-6">
					<div className="tw-flex tw-justify-between tw-items-center">
						<div className="tw-flex tw-gap-4 tw-items-center">
							<h5 className="lg:tw-text-base tw-text-xs tw-text-skyblue tw-font-bold satoshi">
								All Users
							</h5>
						</div>
					</div>
					<div className="tw-mt-10 tw-w-11/12 tw-mx-auto tw-grid lg:tw-grid-cols-3 tw-gap-6">
						{UsersArr?.map((it, i) => (
							<div
								key={i}
								className="tw-bg-[#F2F8FF] tw-rounded-xl tw-border tw-border-[#0269D0] tw-p-6">
								<h2 className="tw-text-main tw-text-2xl tw-font-bold satoshi tw-capitalize">
									{it?.name}
								</h2>
								<div className="tw-mt-4">
									<MainBtn text={"View"} onClick={() => navigate(it?.path)} />
								</div>
							</div>
						))}
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default Plan;
