import { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { MainPaginate } from "../components/button";

const Extractor = ({ pres, setPres }) => {
	let [file, setFile] = useState(null),
		fileRef = useRef(),
		[keys, setKeys] = useState([]);

	let handleChangeImage = e => {
		const filer = e.target.files[0];
		let err = "";

		if (!filer) return (err = `File, ${filer?.name} does not exist`);

		if (err) {
			return toast.error(err);
		} else {
			setFile(filer);
		}
	};

	useEffect(() => {
		if (file) {
			extractor(file);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file]);

	useEffect(() => {
		if (pres?.length > 0 && file) {
			let preKeys = [
				...new Set([...pres?.map(it => Object?.keys(it)?.map(ic => ic))]),
			];

			let newKeys = [];
			for (let f = 0; f < preKeys.length; f++) {
				const element = preKeys[f];
				newKeys = [...newKeys, ...element];
			}
			setKeys([...new Set(newKeys)]);
		}
	}, [pres, file]);
	// console.log({ keys });
	let extractor = file => {
		if (file) {
			// var name = file.name;
			const reader = new FileReader();
			reader.onload = evt => {
				// evt = on_file_select event
				/* Parse data */
				const bstr = evt.target.result;
				const wb = XLSX.read(bstr, { type: "binary" });
				/* Get first worksheet */
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				/* Convert array of arrays */
				const data = XLSX.utils.sheet_to_json(ws, { header: 2 });
				/* Update state */
				// console.log("Data>>>" + data);
				console.log({ data });
				setPres(data);
			};
			reader.readAsBinaryString(file);
		}
	};

	let [range] = useState(9);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	const currentItems = pres?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(pres?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % pres?.length;
		setItemOffset(newOffset);
		// setPage(1 + event?.selected);
	};

	return (
		<>
			<div
				onClick={() => fileRef?.current?.click()}
				className={`tw-py-10 tw-border tw-border-dashed tw-border-gray-300 tw-bg-grey tw-rounded-lg ${
					file && pres?.length > 0 ? "tw-overflow-auto tw-h-[400px]" : ""
				}`}>
				{file && pres?.length > 0 ? (
					<>
						<table className="tw-w-full tw-text-sm tw-text-left tw-text-gray-500 tw-overflow-auto">
							<thead className="tw-text-md tw-text-white tw-capitalize maingradient">
								<tr>
									<th
										scope="col"
										className="tw-px-6 tw-py-3 tw-text-secondary tw-font-medium satoshi tw-text-sm tw-capitalize">
										S/N
									</th>
									{keys?.map((it, i) => (
										<th
											key={i}
											scope="col"
											className="tw-px-6 tw-py-3 tw-text-secondary tw-font-medium satoshi tw-text-sm tw-capitalize">
											{it}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{currentItems?.map((organization, ind) => (
									<tr
										key={ind}
										className="tw-bg-white tw-border-b hover:tw-bg-gray-50">
										<td className="tw-px-6 tw-py-4">{ind + 1}</td>
										{keys?.map((it, i) => (
											<td key={i} className="tw-px-6 tw-py-4">
												{organization?.[it]}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</>
				) : (
					<div>
						<img
							src={require("../assets/file-icon.png")}
							alt=""
							className="tw-mx-auto tw-mb-2"
						/>
						<p className="tw-font-bold tw-text-sm tw-text-center">
							Select a CSV file to upload
						</p>
						<p className="tw-text-xs tw-text-center tw-text-gray-400">
							or drag and drop it here
						</p>
					</div>
				)}
				<input
					ref={fileRef}
					type="file"
					style={{ width: "0" }}
					className="tw-absolute tw--z-10"
					onChange={handleChangeImage}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv"
				/>
			</div>
			{pageCount > 1 && (
				<MainPaginate
					pageCount={pageCount}
					handlePageClick={handlePageClick}
					css="tw-py-2"
				/>
			)}
		</>
	);
};

export default Extractor;
