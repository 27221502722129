import React from "react";
import { useContext } from "react";
import { GlobalState } from "../../data/Context";
import useAuthStore from "../../data/stores/authstore";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { MdLogout } from "react-icons/md";
import Logo from "../../assets/logo.svg";

const Sidebar = ({ children }) => {
  const { nav, toggleNav, sidebarList } = useContext(GlobalState),
		{ isAuth, logout } = useAuthStore(),
		navigate = useNavigate(),
		location = useLocation(),
		currentPath = location.pathname,
		handleLogout = () => {
			logout();
			navigate("/");
		};
	return (
		<div>
			{isAuth && (
				<>
					<button
						type="button"
						className="tw-inline-flex tw-items-center tw-p-2 tw-mt-2 tw-ml-3 tw-text-sm tw-text-gray-500 tw-rounded-lg sm:tw-hidden hover:tw-bg-gray-100 focus:tw-outline-none tw-overflow-visible focus:tw-ring-2 focus:tw-ring-gray-200"
						onClick={toggleNav}>
						<span className="tw-sr-only">Open sidebar</span>

						<AiOutlineMenu size={20} />
					</button>
					<div
						className={`tw-fixed md:tw-hidden tw-top-0 tw-left-0 tw-z-40 tw-w-full tw-h-screen tw-transition-transform tw-bg-black tw-opacity-10 ${
							nav
								? "tw-translate-x-0"
								: "-tw-translate-x-full md:tw-translate-x-0"
						}`}
						onClick={toggleNav}></div>
					<aside
						style={{
							borderRight: "0.7px solid rgba(1, 52, 103, 0.50",
						}}
						className={`tw-fixed tw-top-0 tw-left-0 tw-z-40 tw-w-56 tw-bg-white tw-h-screen tw-pb-8 tw-transition-transform ${
							nav ? "translate-x-0" : "-tw-translate-x-full md:tw-translate-x-0"
						}`}>
						<div className="tw-h-screen tw-relative tw-py-4 tw-overflow-y-scroll noScroll">
							<div className="tw-flex tw-my-6 tw-justify-center">
								<img src={Logo} alt="" className="tw-h-20" />
							</div>
							<div className="tw-flex tw-justify-center">
								<div className="tw-space-y-4 tw-mt-6">
									{sidebarList?.map((item, i) => (
										<div
											onClick={() => navigate(item?.url)}
											key={i}
											className={`tw-flex tw-h-8 tw-w-32 tw-cursor-pointer tw-items-center tw-gap-3 tw-px-3 ${
												currentPath === item?.url ||
												currentPath.includes(item?.url)
													? "tw-bg-[#0269d01a] tw-transition-all tw-duration-500 tw-ease-in-out tw-border-r tw-border-r-[#0269D0]"
													: "tw-bg-transparent"
											}`}>
											{item?.type === "icon" ? (
												item?.icon
											) : (
												<img src={item?.icon} alt="" className="" />
											)}
											<small
												className={`tw-text-sm satoshi tw-capitalize tw-font-normal ${
													currentPath === item?.url ||
													currentPath.includes(item?.url)
														? "tw-text-[#0269D0]"
														: "tw-text-secondary"
												}`}>
												{item?.name}
											</small>
										</div>
									))}
								</div>
							</div>
							<div
								onClick={handleLogout}
								className="tw-absolute tw-cursor-pointer tw-left-4 tw-bottom-5 tw-flex tw-items-center tw-gap-3">
								<MdLogout />
								<small className="tw-text-sm satoshi tw-capitalize tw-font-normal tw-text-secondary">
									Logout
								</small>
							</div>
						</div>
					</aside>
				</>
			)}
			<div className={`${isAuth ? "sm:tw-ml-[224px]" : ""}`}>
				<div className={`${isAuth ? "" : ""}`}>{children}</div>
			</div>
		</div>
	);
};

export default Sidebar;
