import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../../components/maincontainer";
import MainHeader from "../../../components/mainheader";
import { SearchInput } from "../../../components/inputs";
import UserTable from "../../../components/tables/userstable";
import { MainBtn } from "../../../components/button";

const Users = () => {
	const { id } = useParams(),
		navigate = useNavigate();
	return (
		<div>
			<MainContainer>
				<MainHeader text={id || "Students"} small={`All ${id || "Students"}`} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					<div className="tw-flex tw-items-center tw-justify-between">
						<div className="tw-flex tw-gap-4 tw-items-center">
							<h5 className="tw-text-base tw-text-skyblue tw-font-bold satoshi tw-capitalize">
								All {id || "Students"}
							</h5>
							<SearchInput />
						</div>
						<MainBtn
							text={"Bulk Upload"}
							onClick={() => navigate("/bulk-upload", { state: id })}
						/>
					</div>
					<div className="tw-mt-8">
						<UserTable />
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default Users;
