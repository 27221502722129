import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const AreaChart = () => {
	const months = [
		"Ja",
		"Fe",
		"Ma",
		"Ap",
		"Ma",
		"Ju",
		"Ju",
		"Au",
		"Se",
		"Oc",
		"No",
		"De",
	];

	const seriesData = [
		100, 340, 550, 660, 655, 666, 120, 780, 900, 1000, 560, 330,
	];

	const options = {
		chart: {
			type: "area",
			height: 200,
			stacked: true,
			toolbar: {
				show: false,
			},
		},
		xaxis: {
			categories: months,
		},
		yaxis: {
			min: 0,
			max: 1000,
			tickAmount: 4,
			labels: {
				formatter: value => value.toFixed(0),
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},
		fill: {
			type: "gradient",
			gradient: {
				shade: "light",
				gradientToColors: ["#e1ffeb99"],
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				stops: [0, 100],
			},
		},
		markers: {
			size: 4,
			colors: ["#e1ffeb99"],
			strokeColors: "#fff",
			strokeWidth: 2,
		},
		tooltip: {
			theme: "dark",
		},
	};

	const series = [
		{
			name: "Monthly Data",
			data: seriesData,
		},
	];

	return (
		<div className="area-chart">
			<ReactApexChart
				options={options}
				series={series}
				type="area"
				// height={350}
			/>
		</div>
	);
};

export const PieChart = ({ item }) => {
	// console.log({ item });

	const [state, setState] = useState({
		series: [44, 55, 13, 43, 22],
		options: {
			chart: {
				width: 380,
				type: "pie",
			},
			labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
		},
	});

	useEffect(() => {
		if (item?.data) {
			let newData = item?.data?.filter(e => e?.value || e?.item);

			// console.log({ newData });
			setState(prev => {
				let newPrev = { ...prev };
				newPrev.series = newData?.map(it => it?.value);
				let newOptions = {
					...prev?.options,
					labels: newData?.map(it => it?.item),
				};
				newPrev.options = newOptions;
				return newPrev;
			});
		}
	}, [item]);

	// console.log({ state });

	return (
		<div>
			<div id="chart">
				<ReactApexChart
					options={state.options}
					series={state.series}
					type="pie"
					width={380}
				/>
			</div>
			<div id="html-dist"></div>
		</div>
	);
};

export const LineChart = ({ item }) => {
	const [state, setState] = useState({
		series: [
			{
				name: "series1",
				data: [31, 40, 28, 51, 42, 109, 100],
			},
			// {
			// 	name: "series2",
			// 	data: [11, 32, 45, 32, 34, 52, 41],
			// },
		],
		options: {
			chart: {
				height: 350,
				type: "area",
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: "smooth",
			},
			xaxis: {
				type: "datetime",
				categories: [
					"2018-09-19T00:00:00.000Z",
					"2018-09-19T01:30:00.000Z",
					"2018-09-19T02:30:00.000Z",
					"2018-09-19T03:30:00.000Z",
					"2018-09-19T04:30:00.000Z",
					"2018-09-19T05:30:00.000Z",
					"2018-09-19T06:30:00.000Z",
				],
			},
			tooltip: {
				x: {
					format: "dd/MM/yy HH:mm",
				},
			},
		},
	});

	useEffect(() => {
		if (item?.data) {
			let newData = item?.data?.filter(e => e?.value || e?.item);

			// console.log({ newData });
			setState(prev => {
				let newPrev = { ...prev };
				newPrev.series = [
					{
						name: item?.valueLabel,
						data: newData?.map(it => it?.value),
					},
				];

				let newOptions = {
					...prev?.options,
					xaxis: {
						type: "category",
						categories: newData?.map(it => it?.item),
					},
					// tooltip: null,
				};
				newPrev.options = newOptions;
				return newPrev;
			});
		}
	}, [item]);

	// console.log({ state });

	return (
		<div>
			<div id="chart">
				<ReactApexChart
					options={state.options}
					series={state.series}
					type="area"
					height={350}
				/>
			</div>
			<div id="html-dist"></div>
		</div>
	);
};

export const BarChart = ({ item }) => {
	const [state, setState] = useState({
		series: [
			{
				name: "Inflation",
				data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
			},
		],
		options: {
			chart: {
				height: 350,
				type: "bar",
			},
			plotOptions: {
				bar: {
					borderRadius: 10,
					dataLabels: {
						position: "top", // top, center, bottom
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (val) {
					return val;
					// return val + "%";
				},
				offsetY: -20,
				style: {
					fontSize: "12px",
					colors: ["#304758"],
				},
			},

			xaxis: {
				categories: [
					"Jan",
					"Feb",
					"Mar",
					"Apr",
					"May",
					"Jun",
					"Jul",
					"Aug",
					"Sep",
					"Oct",
					"Nov",
					"Dec",
				],
				position: "top",
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				crosshairs: {
					fill: {
						type: "gradient",
						gradient: {
							colorFrom: "#D8E3F0",
							colorTo: "#BED1E6",
							stops: [0, 100],
							opacityFrom: 0.4,
							opacityTo: 0.5,
						},
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			yaxis: {
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					show: false,
					formatter: function (val) {
						return val + "%";
					},
				},
			},
			title: {
				text: "Monthly Inflation in Argentina, 2002",
				floating: true,
				offsetY: 330,
				align: "center",
				style: {
					color: "#444",
				},
			},
		},
	});

	useEffect(() => {
		if (item?.data) {
			let newData = item?.data?.filter(e => e?.value || e?.item);

			// console.log({ newData });
			setState(prev => {
				let newPrev = { ...prev };
				newPrev.series = [
					{
						name: item?.valueLabel,
						data: newData?.map(it => it?.value),
					},
				];

				let newOptions = {
					...prev?.options,
					xaxis: {
						type: "category",
						categories: newData?.map(it => it?.item),
					},
					// tooltip: null,
				};

				let newTitle = { ...newOptions?.title };
				newTitle.text = item?.title;

				newOptions.title = newTitle;

				newPrev.options = newOptions;
				return newPrev;
			});
		}
	}, [item]);

	// console.log({ state });

	return (
		<div>
			<div id="chart">
				<ReactApexChart
					options={state.options}
					series={state.series}
					type="bar"
					height={350}
				/>
			</div>
			<div id="html-dist"></div>
		</div>
	);
};

export default AreaChart;
