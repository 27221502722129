import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MainBtn, DeleteBtn } from "../button";
// import Video from "../../assets/video.svg";
import { VideoInput } from "../inputs";
import { useNavigate } from "react-router-dom";
import { ContentWriteup } from "../../pages/courses/[id]";
import { ContentMainDetails } from "../../pages/courses/single-template";

const ModuleModal = ({ handleClose, datum, setModal }) => {
	// console.log({datum});
	let navigate = useNavigate();
	return (
		<div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
			<div className="lg:tw-w-1/2 tw-w-5/6 tw-mx-auto tw-p-4 tw-bg-white tw-rounded-xl tw-h-5/6 tw-overflow-y-auto">
				<div className="tw-flex tw-justify-between tw-items-center">
					<h2 className="tw-text-2xl tw-font-bold tw-text-secondary satoshi">
						{datum?.data?.title}
					</h2>
					<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
				</div>
				{datum?.type === "Content" ? (
					<>
						{datum?.data?.moduleSection?.map((it, i) => (
							<ContentMainDetails it={it} key={i} />
						))}
					</>
				) : (
					<>
						{/* <pre className="tw-text-xs satoshi tw-font-normal tw-pt-5 tw-text-main">
					{datum?.data?.description}
				</pre> */}
						{ContentWriteup(
							datum?.data?.description,
							"tw-text-xs satoshi tw-font-normal tw-py-5 tw-text-main"
						)}
						<VideoInput state={datum?.data} name={"videoLink"} noTitle />
						{/* <img src={Video} alt="" className="tw-my-5" /> */}
						<div className="tw-py-5 tw-border-t tw-border-secondary tw-flex tw-gap-5">
							<MainBtn
								text={"Edit Section"}
								onClick={() =>
									navigate(
										`/courses/add-course?course=${datum?.course}&type=section&section=${datum?.data?._id}&module=${datum?.module}`,
										{ state: datum?.data }
									)
								}
							/>
							<DeleteBtn
								onClick={() => setModal("delete")}
								text={"Delete Section"}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ModuleModal;
