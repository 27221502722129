import React, { useRef, useState } from "react";
import Notification from "../assets/notification.svg";
import Avatar from "../assets/avatar.svg";
import { AiOutlineClose, AiOutlineDown } from "react-icons/ai";
import { SearchInput } from "./inputs";
import { MainBtn } from "./button";
import { useNavigate } from "react-router-dom";
import Upload from "../assets/upload.svg";
import useAuthStore from "../data/stores/authstore";
import Edit from "../assets/edit.svg";
import { MdLogout } from "react-icons/md";
import Profile from "../assets/profile.svg";

const MainHeader = ({ text, small }) => {
	const [notification, setNotification] = useState(false),
		[profile, setProfile] = useState(false),
		[edit, setEdit] = useState(false),
		handleEdit = () => {
			setProfile(false);
			setEdit(true);
		};
	return (
		<div>
			<div className="tw-flex tw-justify-between tw-items-center tw-h-20 tw-bg-transparent">
				<div>
					<h2 className="tw-text-2xl tw-capitalize satoshi tw-text-skyblue tw-font-bold">
						{text}
					</h2>
					<p className="tw-text-xs satoshi tw-text-main tw-font-normal">
						{small}
					</p>
				</div>
				<div className="tw-flex tw-items-center tw-gap-6">
					{/* <div className="tw-hidden lg:tw-block">
						<SearchInput />
					</div> */}
					<div className="tw-flex  tw-gap-2">
						<img
							onClick={() => setNotification(true)}
							src={Notification}
							alt=""
							className="tw-cursor-pointer"
						/>
						<div
							onClick={() => setProfile(true)}
							className="tw-flex tw-cursor-pointer tw-items-center">
							<img src={Avatar} alt="" className="" />
							<AiOutlineDown />
						</div>
					</div>
				</div>
			</div>
			{notification && (
				<NotificationSection handleClose={() => setNotification(false)} />
			)}
			{profile && (
				<ProfileSection
					handleClose={() => setProfile(false)}
					handleEdit={handleEdit}
				/>
			)}
			{edit && <EditSection handleClose={() => setEdit("")} />}
		</div>
	);
};

const NotificationSection = ({ handleClose }) => {
	const Arr = [
		{
			title: "Coming Soon",
			desc: "et, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co",
			date: "14th May, 2023",
			time: "2:00PM",
		},
		{
			title: "Coming Soon",
			desc: "et, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co",
			date: "14th May, 2023",
			time: "2:00PM",
		},
		{
			title: "Coming Soon",
			desc: "et, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co",
			date: "14th May, 2023",
			time: "2:00PM",
		},
		{
			title: "Coming Soon",
			desc: "et, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co",
			date: "14th May, 2023",
			time: "2:00PM",
		},
		{
			title: "Coming Soon",
			desc: "et, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co",
			date: "14th May, 2023",
			time: "2:00PM",
		},
	];
	return (
		<div className="lg:tw-absolute tw-transition-transform  tw-duration-500 lg:tw-p-5 tw-p-2 lg:tw-right-2 tw-z-50  tw-rounded-xl tw-shadow-lg tw-bg-white lg:tw-w-1/3 tw-w-11/12 tw-mx-auto lg:tw-mx-0 tw-h-screen tw-overflow-y-scroll noScroll">
			<div className="tw-min-h-full">
				<div className="tw-mb-10 tw-float-right">
					<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
				</div>
				<h3 className="tw-text-2xl tw-font-bold tw-text-main">Notifications</h3>
				<div className="tw-mt-8 tw-space-y-4">
					{Arr?.map((item, i) => (
						<div
							key={i}
							style={{
								border: " 0.5px solid var(--Stokee, rgba(78, 83, 94, 0.50))",
							}}
							className="tw-rounded-lg tw-p-3">
							<h5 className="tw-text-base tw-font-bold tw-text-main">
								{item?.title}
							</h5>
							<p className="tw-text-sm tw-text-main">{item?.desc}</p>
							<div className="tw-flex tw-justify-between tw-w-full tw-items-center">
								<small className="tw-text-xs tw-text-main tw-font-medium">
									{item?.time}
								</small>
								<small className="tw-text-xs tw-text-main tw-font-medium">
									{item?.date}
								</small>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const ProfileSection = ({ handleClose, handleEdit }) => {
	const { logout,user } = useAuthStore(),
		navigate = useNavigate(),
		handleLogout = () => {
			logout();
			navigate("/");
		};

	return (
		<div className="lg:tw-absolute tw-relative tw-transition-transform  tw-duration-500 lg:tw-p-5 tw-p-2 lg:tw-right-2 tw-z-50  tw-rounded-xl tw-shadow-lg tw-bg-white lg:tw-w-1/3 tw-w-11/12 tw-mx-auto lg:tw-mx-0 tw-h-56">
			<div className="tw-float-right">
				<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
			</div>
			<div className="tw-flex tw-mt-8 tw-gap-4 tw-items-center">
				<img src={user?.image?.url || Profile} alt="" className="tw-h-28" />
				<div>
					<h5 className="tw-text-2xl tw-text-main tw-font-extrabold">
						{user?.lastName} {user?.firstName}
					</h5>
					<img
						onClick={handleEdit}
						src={Edit}
						alt=""
						className="tw-cursor-pointer"
					/>
				</div>
			</div>
			<div
				onClick={handleLogout}
				className="tw-absolute tw-cursor-pointer tw-right-8 tw-bottom-5 tw-flex tw-items-center tw-gap-3">
				<MdLogout />
				<small className="tw-text-sm satoshi tw-capitalize tw-font-normal tw-text-secondary">
					Logout
				</small>
			</div>
		</div>
	);
};

const EditSection = ({ handleClose }) => {
	const navigate = useNavigate();
	return (
		<div className="lg:tw-absolute tw-relative tw-transition-transform  tw-duration-500 lg:tw-p-5 tw-p-2 lg:tw-right-2 tw-z-50  tw-rounded-xl tw-shadow-lg tw-bg-white lg:tw-w-80 tw-w-11/12 tw-mx-auto lg:tw-mx-0">
			<div className="tw-float-right">
				<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
			</div>
			<div className="tw-mt-8">
				<h5 className="tw-text-base tw-text-secondary tw-font-bold">
					Edit profile
				</h5>
				<form action="" className="tw-mt-4">
					<div>
						<p className="tw-text-sm tw-text-secondary tw-font-medium">
							Full name
						</p>
						<input
							type="text"
							name="full_name"
							className="tw-w-full placeholder:tw-text-gray-300 tw-pl-4 tw-h-10 tw-rounded-lg tw-text-sm tw-text-secondary tw-font-normal"
							placeholder="Henry"
							style={{
								border: "0.5px solid var(--Stokee, rgba(78, 83, 94, 0.50))",
							}}
							id=""
						/>
					</div>
					<div className="tw-mt-5">
						<ImageInput />
					</div>
					<div className="tw-mt-5">
						<MainBtn onClick={() => navigate("/")} text={"Update Profile"} />
					</div>
				</form>
			</div>
		</div>
	);
};
export const ImageInput = ({ name, onChange }) => {
	const ref = useRef();
	const handleClick = () => {
		ref.current?.click();
	};
	return (
		<div>
			<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
				Profile Picture
			</p>
			<div
				onClick={handleClick}
				className="tw-h-24 tw-cursor-pointer tw-rounded-xl tw-border-secondary tw-border tw-w-full tw-flex tw-justify-center tw-items-center">
				<div className="">
					<img src={Upload} alt="" className="tw-mx-auto" />
					<small className="tw-text-sm tw-text-[#275A7F] tw-font-medium satoshi tw-text-center">
						Click to upload
					</small>
					<h6 className="tw-text-xs tw-text-[#275A7F] tw-font-medium satoshi tw-text-center">
						(jpg, png)
					</h6>
					<input
						type="file"
						name={name}
						onChange={onChange}
						ref={ref}
						accept=".png, .jpg, .png"
						id=""
						className="tw-hidden"
					/>
				</div>
			</div>
		</div>
	);
};

export default MainHeader;
