import React, { useEffect, useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Total from "../../assets/totalcourse.svg";
import Student from "../../assets/totalstudent.svg";
import Ongoing from "../../assets/ongoing.svg";
import Completed from "../../assets/completed.svg";
import { MainBtn } from "../../components/button";
import { AiFillEye } from "react-icons/ai";
import AreaChart from "../../components/charts/area";
import UserTable from "../../components/tables/userstable";
import useUserStore from "../../data/stores/userstore";
import useCourseStore from "../../data/stores/coursestore";
// import AddCourseTypeModal from "../../components/modals/addcoursetypemodal";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const users = useUserStore(),
    courses = useCourseStore();
  const Arr = [
      {
        name: "total courses",
        bg: "rgba(2, 105, 208, 0.08)",
        value: courses?.data?.totalDocs || "0",
        icon: Total,
      },
      {
        name: "Total Student",
        bg: "rgba(2, 105, 208, 0.08)",
        value: users?.data?.totalDocs || "0",
        icon: Student,
      },
      {
        name: "Ongoing courses",
        bg: "rgba(4, 167, 167, 0.08)",
        value: <CourseDashTable type={""} />,
        icon: Ongoing,
      },
      {
        name: "completed course",
        bg: "rgba(98, 18, 177, 0.08)",
        value: <CourseDashTable type={"completed"} />,
        icon: Completed,
      },
    ],
    navigate = useNavigate();

  return (
    <div>
      <MainContainer>
        <MainHeader text={"Dashboard"} small={"A quick glance "} />
        <div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
          <div className="tw-flex tw-justify-between tw-items-center">
            <h3 className="tw-text-base tw-text-skyblue tw-font-bold satoshi">
              Overview
            </h3>
            <MainBtn
              onClick={() => navigate("/courses/add-course-new")}
              text={"Add course"}
            />
          </div>
          <div className="tw-mt-8 tw-grid lg:tw-grid-cols-4 tw-gap-5">
            {Arr?.map((item, i) => (
              <div
                style={{
                  background: item?.bg,
                }}
                key={i}
                className="tw-h-24 tw-rounded-lg tw-flex tw-items-center tw-gap-3 tw-pl-6"
              >
                <div className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-shadow-md">
                  {" "}
                  <img src={item?.icon} alt="" className="" />
                </div>
                <div>
                  <h5 className="tw-text-main tw-pt-2 tw-capitalize tw-text-sm satoshi tw-font-medium">
                    {item?.name}
                  </h5>
                  <h5 className="tw-text-main satoshi tw-font-medium tw-text-[32px]">
                    {item?.value}
                  </h5>
                </div>
              </div>
            ))}
          </div>
          <div className="tw-mt-10 tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
            <div
              style={{
                border: "1px solid rgba(1, 52, 103, 0.30)",
              }}
              className="lg:tw-w-[40%]  tw-rounded-2xl"
            >
              <div
                style={{
                  borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
                }}
                className="tw-p-5 "
              >
                <h3 className="tw-text-xl tw-text-skyblue tw-font-bold satoshi">
                  Website Impressions
                </h3>
                <div className="tw-flex tw-mt-2 tw-gap-1 tw-items-center">
                  <div className="tw-h-8 tw-w-8 tw-bg-[#D9D9D966] tw-rounded-lg tw-flex tw-items-center tw-justify-center">
                    <AiFillEye />
                  </div>
                  <h6 className="tw-text-xl tw-pt-1 tw-font-medium satoshi tw-text-secondary">
                    67 <span className="smallText">views</span>
                  </h6>
                </div>
              </div>
              <div className="">
                <h6 className="tw-text-base tw-p-5 tw-font-medium tw-text-skyblue satoshi">
                  Performance
                </h6>
                <AreaChart />
              </div>
            </div>
            <div
              style={{
                border: "1px solid rgba(1, 52, 103, 0.30)",
              }}
              className="lg:tw-w-[60%] tw-overflow-y-scroll tw-rounded-2xl tw-p-4"
            >
              <h3 className="tw-text-xl tw-text-skyblue tw-font-bold satoshi">
                Students
              </h3>
              <div className="tw-mt-5">
                <UserTable />
              </div>
            </div>
          </div>
        </div>
      </MainContainer>
      {/* {modal === "add-course" && (
        <AddCourseTypeModal handleClose={() => setModal("")} />
      )} */}
    </div>
  );
};

export default Dashboard;

export const UserCourseTable = ({ type, datum }) => {
  let { allCourse } = useCourseStore(),
    [courses, setCourses] = useState(null);
  useEffect(() => {
    if (datum) {
      let findCourses = allCourse?.docs?.filter((it) => {
        let findP = it?.partakers?.find((ij) => ij?._id === datum?._id);

        return findP;
      });
      setCourses(findCourses);
    }
  }, [datum, allCourse]);
  return (
    <>{type === "length" ? courses?.length || 0 : courses?.[0]?.title || ""}</>
  );
};

export const CourseDashTable = ({ type, course }) => {
  let { courses, courses2 } = useCourseData();
  // console.log({
  // 	courses: courses?.reduce(
  // 		(ac, i) => (ac += Number(i?.progress?.score || 0)),
  // 		0
  // 	),
  // 	length: courses?.length,
  // });
  // console.log({ courses });
  if (!courses) return;

  return (
    <>
      {Number(
        type === "completed"
          ? courses?.filter((it) => it?.progress?.status === "completed")
              ?.length || 0
          : type === "not-completed"
          ? courses?.filter((it) => it?.progress?.status !== "completed")
              ?.length || 0
          : type === "lock-out" && course
          ? courses
              ?.filter((it) => it?.progress?.status === "lock-out")
              ?.filter((it) => it?.course?._id === course)?.length || 0
          : type === "lock-out"
          ? courses?.filter((it) => it?.progress?.status === "lock-out")
              ?.length || 0
          : type === "certificate"
          ? courses?.filter((it) => it?.progress?.certificate)?.length || 0
          : type === "not-certificate"
          ? courses?.filter((it) => !it?.progress?.certificate)?.length || 0
          : type === "total"
          ? courses?.length || 0
          : type === "higher"
          ? courses?.sort(
              (a, b) => b?.progress?.score - a?.progress?.score
            )?.[0]?.progress?.score || 0
          : type === "lower"
          ? courses?.sort((a, b) => b?.progress?.score - a?.progress?.score)?.[
              courses?.length - 1
            ]?.progress?.score || 0
          : type === "average"
          ? courses?.reduce(
              (ac, i) => (ac += Number(i?.progress?.score || 0)),
              0
            ) / courses?.length || 0
          : type === "most-taken"
          ? courses2?.sort(
              (a, b) => b?.partakers?.length - a?.partakers?.length
            )?.[0]?.partakers?.length || 0
          : type === "lowest-taken"
          ? courses2?.sort(
              (a, b) => b?.partakers?.length - a?.partakers?.length
            )?.[courses?.length - 1]?.partakers?.length || 0
          : type === "average-taken"
          ? courses2?.reduce(
              (ac, i) => (ac += Number(i?.partakers?.length || 0)),
              0
            ) / courses?.length || 0
          : course
          ? courses
              ?.filter((it) => it?.progress?.status === "in-progress")
              ?.filter((it) => it?.course?._id === course)?.length || 0
          : courses?.filter((it) => it?.progress?.status === "in-progress")
              ?.length || 0
      ).toFixed(0)}
    </>
  );
};

export const useCourseData = () => {
  let { allCourse, data } = useCourseStore(),
    [courses, setCourses] = useState(null),
    [courses2, setCourses2] = useState(null);
  useEffect(() => {
    if (allCourse) {
      let newCo = [];
      for (let co = 0; co < allCourse?.docs.length; co++) {
        const element = allCourse?.docs[co];

        newCo = element?.partakers
          ? [
              ...newCo,
              ...element?.partakers?.map((it) => {
                return {
                  ...it,
                  course: { title: element?.title, _id: element?._id },
                };
              }),
            ]
          : newCo;
      }
      setCourses(newCo);
    }
    setCourses2(allCourse?.docs || data?.docs);
  }, [allCourse, data]);

  return { courses, courses2 };
};
