import React, { useEffect, useState } from "react";
import { LoadMore, MainPaginate, Tablebtn } from "../button";
import { useLocation, useNavigate } from "react-router-dom";
import { useGroupsStore } from "../../data/stores/loggerstore";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import moment from "moment";
import { SlOptionsVertical } from "react-icons/sl";
import { ShareDocumentCourseModal } from "../modal/selectcourse";

const GroupsTable = () => {
	const navigate = useNavigate();
	const Headers = [
		"group name",
		"No. Of Members",
		"assigned courses",
		"date added",
		"action",
	];
	// data = [
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	//   {
	//     name: "John Leader",
	//     members: "50",
	//     date_registered: "07 May, 2023",
	//     no_of_courses: "4",
	//   },
	// ];

	let [datum, setDatum] = useState({ docs: [] }),
		{ getLogger, mainSearch, isFound, data } = useGroupsStore(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall("get", `/api/v1/groups`, null, getLogger);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall(
			"get",
			`/api/v1/groups?limit=${data?.limit * data?.nextPage}`,
			null,
			getLogger
		);
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.map(item => (
								<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">{item?.title}</td>
								<td className="tableText">{item?.members?.length}</td>
								<td className="tableText">{item?.courses?.length}</td>
								<td className="tableText">
									{moment(item?.createdAt).format("Do MMMM, YYYY")}
								</td>
								<td>
									<div className="">
										<Tablebtn
											onClick={() =>
												navigate(`/groups/${item?.title || i}`, { state: item })
											}
											text={"View"}
										/>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="tw-mt-10 tw-flex tw-justify-center tw-w-44 tw-items-center tw-mx-auto">
					<LoadMore
						next={data?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</div>
	);
};

export const AssignedTable = ({ setTab }) => {
	// data = [
	// 	{
	// 		name: "John Leader",
	// 		assigned_by: "John Leader",
	// 		date_registered: "07 May, 2023",
	// 		no_of_courses: "4",
	// 	},
	// 	{
	// 		name: "John Leader",
	// 		assigned_by: "John Leader",
	// 		date_registered: "07 May, 2023",
	// 		no_of_courses: "4",
	// 	},
	// 	{
	// 		name: "John Leader",
	// 		assigned_by: "John Leader",
	// 		date_registered: "07 May, 2023",
	// 		no_of_courses: "4",
	// 	},
	// 	{
	// 		name: "John Leader",
	// 		assigned_by: "John Leader",
	// 		date_registered: "07 May, 2023",
	// 		no_of_courses: "4",
	// 	},
	// 	{
	// 		name: "John Leader",
	// 		assigned_by: "John Leader",
	// 		date_registered: "07 May, 2023",
	// 		no_of_courses: "4",
	// 	},
	// ];

	let [datum, setDatum] = useState({ docs: [] }),
		{ mainSearch, isFound, courses, getDynamicLogger, data } = useGroupsStore(),
		[loading, setLoading] = useState(false),
		{ state } = useLocation(),
		[mainState, setMainState] = useState(null),
		{ user, userPage } = useAuthStore(),
		navigate = useNavigate(),
		[active, setActive] = useState(null),
		[modal, setModal] = useState(null),
		[item, setItem] = useState(null);

	const Headers = [
			"name",
			"Assign by",
			"date assigned",
			"document shared",
			"action",
		],
		handleActive = i => {
			if (active === i) {
				setActive(null);
			} else {
				setActive(i);
			}
		},
		actions = [
			{
				name: "view course",
				action: st =>
					navigate(`/courses/manage-course`, {
						state: st,
					}),
			},
			{
				name: "share document",
				action: st => {
					setModal("share-document");
					setItem(st);
				},
			},
		];

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(courses);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courses, isFound, mainSearch]);

	useEffect(() => {
		data?.docs?.map(it => it?._id === state?._id && setMainState(it));
	}, [data, state]);

	useEffect(() => {
		if (!mainState) return;
		apiCall(
			"put",
			`/api/v1/groups/manage-courses?group=${mainState?._id}&institutionCourse=${user?._id}`,
			{ courses: mainState?.courses?.map(it => it?.course) },
			data => getDynamicLogger(data, "courses"),
			null,
			"noToast"
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainState, user]);
	// console.log({ state, mainState });

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall(
			"put",
			`/api/v1/groups/manage-courses?group=${
				mainState?._id
			}&institutionCourse=${user?._id}&limit=${datum?.limit * datum?.nextPage}`,
			{ courses: mainState?.courses?.map(it => it?.course) },
			data => getDynamicLogger(data, "courses"),
			null,
			"noToast"
		);
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.filter(it => it)?.map(item => (
								<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">{item?.title}</td>
								<td className="tableText">
									<GetDateAndAssignee
										type={"user"}
										data={data?.docs}
										mainState={mainState}
										item={item}
									/>
								</td>
								<td className="tableText">
									<GetDateAndAssignee
										type={"date"}
										data={data?.docs}
										mainState={mainState}
										item={item}
									/>
								</td>
								<td
									onClick={() => {
										if (setTab) {
											setTab(item);
										}
									}}
									className="tableText tw-text-[#0B2239] tw-font-medium tw-underline tw-cursor-pointer">
									View Document
								</td>
								<td>
									{userPage !== "institution" && (
										<div className="">
											<Tablebtn
												text={"Start"}
												onClick={() =>
													navigate(`/courses/${item?.title}`, { state: item })
												}
											/>
										</div>
									)}
									{userPage === "institution" && (
										<div className="tw-relative">
											<div className="tw-h-6 tw-ml-7 tw-w-3 tw-rounded-xl tw-flex tw-justify-center tw-items-center tw-border tw-border-[#87C3FF]">
												<SlOptionsVertical
													onClick={() => handleActive(i)}
													className="tw-cursor-pointer"
													color="#87C3FF"
												/>
											</div>
											{active === i && (
												<div className="tw-absolute tw-z-50 tw-rounded tw-bg-white tw-w-36 tw-p-2 tw-top-8 tw-left-[-5rem]">
													{actions?.map(act => (
														<button
															onClick={() =>
																act?.action ? act?.action(item) : {}
															}
															style={{
																borderBottom: "0.5px solid #00000033",
															}}
															className="tw-h-8 tw-w-full tw-text-left tw-capitalize tw-text-sm tw-font-normal tw-text-secondary">
															{act?.name}
														</button>
													))}
												</div>
											)}
										</div>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="tw-mt-10 tw-flex tw-justify-center tw-w-44 tw-items-center tw-mx-auto">
					<LoadMore
						next={datum?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
				{modal === "share-document" && (
					<ShareDocumentCourseModal
						handleClose={() => {
							setModal("");
							setItem(null);
						}}
						item={item}
					/>
				)}
			</div>
		</div>
	);
};

export const OngoingTable = ({ type, setTab }) => {
	const Headers = [
		"name",
		"Assign by",
		"date assigned",
		"document shared",
		type !== "completed" ? "action" : "",
	];
	// data = [
	// 	{
	// 		name: "John Leader",
	// 		assigned_by: "John Leader",
	// 		date_registered: "07 May, 2023",
	// 		no_of_courses: "4",
	// 	},
	// 	{
	// 		name: "John Leader",
	// 		assigned_by: "John Leader",
	// 		date_registered: "07 May, 2023",
	// 		no_of_courses: "4",
	// 	},
	// 	{
	// 		name: "John Leader",
	// 		assigned_by: "John Leader",
	// 		date_registered: "07 May, 2023",
	// 		no_of_courses: "4",
	// 	},
	// 	{
	// 		name: "John Leader",
	// 		assigned_by: "John Leader",
	// 		date_registered: "07 May, 2023",
	// 		no_of_courses: "4",
	// 	},
	// 	{
	// 		name: "John Leader",
	// 		assigned_by: "John Leader",
	// 		date_registered: "07 May, 2023",
	// 		no_of_courses: "4",
	// 	},
	// ];

	let [datum, setDatum] = useState({ docs: [] }),
		{ mainSearch, isFound, courses, getDynamicLogger, data } = useGroupsStore(),
		[loading, setLoading] = useState(false),
		{ state } = useLocation(),
		[mainState, setMainState] = useState(null),
		{ user } = useAuthStore(),
		navigate = useNavigate();

	useEffect(() => {
		let dd = [],
			dd2 = {};
		if (isFound) {
			dd = mainSearch?.docs;
			dd2 = mainSearch;
		} else {
			dd = courses?.docs;
			dd2 = courses;
		}
		if (type === "completed")
			dd = dd?.filter(it => it?.progress?.status === "completed");
		if (type === "lock-out")
			dd = dd?.filter(it => it?.progress?.status === "lock-out");
		setDatum({ ...dd2, docs: dd });
	}, [courses, isFound, mainSearch, type]);

	useEffect(() => {
		data?.docs?.map(it => it?._id === state?._id && setMainState(it));
	}, [data, state]);

	// useEffect(() => {
	// 	apiCall(
	// 		"put",
	// 		`/api/v1/groups/manage-courses?group=${state?._id}&institutionCourse=${user?._id}`,
	// 		{ courses: state?.courses },
	// 		data => getDynamicLogger(data, "courses"),
	// 		null,
	// 		"noToast"
	// 	);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [state, user]);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall(
			"put",
			`/api/v1/groups/manage-courses?group=${
				mainState?._id
			}&institutionCourse=${user?._id}&limit=${datum?.limit * datum?.nextPage}`,
			{ courses: mainState?.courses?.map(it => it?.course) },
			data => getDynamicLogger(data, "courses"),
			null,
			"noToast"
		);
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.filter(it => it)?.map(item => (
								<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">{item?.title}</td>
								<td className="tableText">
									<GetDateAndAssignee
										type={"user"}
										data={data?.docs}
										mainState={mainState}
										item={item}
									/>
								</td>
								<td className="tableText">
									<GetDateAndAssignee
										type={"date"}
										data={data?.docs}
										mainState={mainState}
										item={item}
									/>
								</td>
								<td
									onClick={() => {
										if (setTab) {
											setTab(item);
										}
									}}
									className="tableText tw-text-[#0B2239] tw-font-medium tw-underline tw-cursor-pointer">
									View Document
								</td>
								{type !== "completed" && (
									<td>
										<div className="">
											<Tablebtn
												text={"Resume"}
												onClick={() =>
													navigate(`/courses/${item?.title}`, { state: item })
												}
											/>
										</div>
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
				<div className="tw-mt-10 tw-flex tw-justify-center tw-w-44 tw-items-center tw-mx-auto">
					<LoadMore
						next={datum?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</div>
	);
};

export const CompletedTable = () => {
	const Headers = ["name", "Assign by", "date assigned", "document shared"],
		data = [
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Leader",
				assigned_by: "John Leader",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
		];
	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.map(item => (
								<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">{item?.name}</td>
								<td className="tableText">{item?.assigned_by}</td>
								<td className="tableText">{item?.date_registered}</td>
								<td className="tableText tw-text-[#0B2239] tw-font-medium tw-underline tw-cursor-pointer">
									View Document
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export const GetDateAndAssignee = ({ type, item, data, mainState }) => {
	let [stat, setStat] = useState(null);

	useEffect(() => {
		let dd = data?.find(it => it?._id === mainState?._id);
		if (dd) {
			let newD = dd?.courses?.find(it => it?.course === item?._id);
			if (newD) {
				setStat(newD);
			}
		}
	}, [item, data, mainState]);

	console.log({ stat });

	return (
		<>
			{type === "user" ? (
				<>
					{stat?.user?.firstName} {stat?.user?.lastName}
				</>
			) : type === "date" ? (
				<>{moment(stat?.createdAt)?.format("Do MMMM, YYYY")}</>
			) : (
				""
			)}
		</>
	);
};

export const CoursesDocumentTable = ({ item }) => {
	let [datum, setDatum] = useState({ docs: [] }),
		{ mainSearch, isFound, document, getDynamicLogger, data } =
			useGroupsStore(),
		[loading, setLoading] = useState(false),
		{ state } = useLocation(),
		[mainState, setMainState] = useState(null);

	const Headers = ["File name", "shared by", "date shared", "action"];

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(document);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document, isFound, mainSearch]);

	useEffect(() => {
		data?.docs?.map(it => it?._id === state?._id && setMainState(it));
	}, [data, state]);

	useEffect(() => {
		if (!mainState) return;
		apiCall(
			"get",
			`/api/v1/groups/manage-courses-document?group=${mainState?._id}&course=${item?._id}`,
			null,
			data => getDynamicLogger(data, "document")
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainState, item]);
	// console.log({ state, mainState });

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall(
			"get",
			`/api/v1/groups/manage-courses-document?group=${mainState?._id}&course=${
				item?._id
			}&limit=${datum?.limit * datum?.nextPage}`,
			null,
			data => getDynamicLogger(data, "document")
		);
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.filter(it => it)?.map(item => (
								<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText">{item?.file?.name}</td>
								<td className="tableText">
									{item?.user?.firstName} {item?.user?.lastName}
								</td>
								<td className="tableText">
									{moment(item?.createdAt).format("Do MMMM, YYYY")}
								</td>
								<td
									onClick={() => {
										if (item?.file?.url) {
											window.open(item?.file?.url, "_blank");
										}
									}}
									className="tableText tw-text-[#0B2239] tw-font-medium tw-underline tw-cursor-pointer">
									View Document
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="tw-mt-10 tw-flex tw-justify-center tw-w-44 tw-items-center tw-mx-auto">
					<LoadMore
						next={datum?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</div>
	);
};

export default GroupsTable;
