import React, { useState, useEffect, useContext } from "react";
import { LoadMore, MainPaginate, Tablebtn } from "../button";
import { useNavigate } from "react-router-dom";
import { useCouponStore } from "../../data/stores/loggerstore";
import { apiCall } from "../../data/stores/authstore";
import moment from "moment";
import { GlobalState } from "../../data/Context";
import useCourseStore from "../../data/stores/coursestore";

const CouponsTable = ({ status = "active" }) => {
	const navigate = useNavigate();
	const Headers = [
		"date created",
		"coupon code",
		"discount amount",
		"course name",
		"expiration date",
		"maximum usage",
		"action",
	];
	const tableData = [
		{
			dateCreated: "07 May, 2023",
			couponCode: "yqjhj9o3797189",
			discountAmount: "Fixed - ₦60,000",
			courseName: "Leadership 020",
			expirationDate: "07 May, 2023",
			maxUsage: 12,
			action: "View",
		},
		{
			dateCreated: "12 Jun, 2023",
			couponCode: "abc123xy998723",
			discountAmount: "Percentage - 20%",
			courseName: "Project Management 101",
			expirationDate: "12 Dec, 2023",
			maxUsage: 50,
			action: "View",
		},
		{
			dateCreated: "15 Jul, 2023",
			couponCode: "zkl987pl443221",
			discountAmount: "Fixed - ₦30,000",
			courseName: "Data Science Basics",
			expirationDate: "15 Jan, 2024",
			maxUsage: 30,
			action: "View",
		},
		{
			dateCreated: "01 Aug, 2023",
			couponCode: "mnop45678xyz12",
			discountAmount: "Percentage - 10%",
			courseName: "Business Analytics Advanced",
			expirationDate: "01 Feb, 2024",
			maxUsage: 100,
			action: "View",
		},
		{
			dateCreated: "20 Sep, 2023",
			couponCode: "cde901gh456789",
			discountAmount: "Fixed - ₦50,000",
			courseName: "Software Engineering Fundamentals",
			expirationDate: "20 Mar, 2024",
			maxUsage: 20,
			action: "View",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData }),
		{ getLogger, mainSearch, isFound, data, getDynamicLogger } =
			useCouponStore(),
		coupons = useCouponStore(),
		courses = useCourseStore(),
		[loading, setLoading] = useState(false),
		{ numberWithCommas } = useContext(GlobalState);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else if (status && status !== "active") {
			setDatum(coupons[status]);
		} else setDatum(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isFound, mainSearch, status]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/course/manage-coupon?status=${status}`,
			null,
			status !== "active" ? d => getDynamicLogger(d, status) : getLogger
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall(
			"get",
			`/api/v1/course/manage-coupon?status=${status}&limit=${
				datum?.limit * datum?.nextPage
			}`,
			null,
			status !== "active" ? d => getDynamicLogger(d, status) : getLogger
		);
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<div>
				<div className=" tw-overflow-x-scroll">
					<table className="tw-min-w-full tw-min-h-full tw-table-auto">
						<thead>
							<tr>
								{Headers?.map(item => (
									<th className="tw-text-[#013467] tw-text-left tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
										{item}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{currentItems?.map((item, i) => (
								<tr
									style={{
										borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
									}}
									key={i}>
									<td className="tableText">
										{moment(item?.createdAt).format("DD MMM, YYYY")}
									</td>
									<td className="tableText tw-capitalize">{item?.code}</td>
									<td className="tableText">
										<span className="tw-capitalize">{item?.discountType}</span>{" "}
										-{item?.discountType === "fixed" ? "₦" : ""}{" "}
										{numberWithCommas(item?.discount)}
										{item?.discountType === "percentage" ? "%" : ""}
									</td>
									<td className="tableText">
										{
											courses?.data?.docs?.find(it => it?._id === item?.course)
												?.title
										}
									</td>
									<td className="tableText">
										{moment(item?.expirationDate).format("DD MMM, YYYY")}
									</td>
									<td className="tableText">{item?.maximumUsage}</td>
									<td>
										<div className="tw-ml-2">
											<Tablebtn
												onClick={() =>
													navigate(`/coupons/${item?.course?.title || i}`, {
														state: item,
													})
												}
												text={"View"}
											/>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="tw-mt-10 tw-flex tw-justify-center tw-w-44 tw-items-center tw-mx-auto">
						<LoadMore
							next={datum?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export const CouponUserTable = ({ datum: incomingState, setNoOfUsers }) => {
	const Headers = ["individual name", "course name", "date used"];
	const tableData = [
		{
			individualName: "John Doe",
			courseName: "Leadership 020",
			expirationDate: "07 May, 2023",
		},
		{
			individualName: "John Doe",
			courseName: "Leadership 020",
			expirationDate: "07 May, 2023",
		},
		{
			individualName: "John Doe",
			courseName: "Leadership 020",
			expirationDate: "07 May, 2023",
		},
		{
			individualName: "John Doe",
			courseName: "Leadership 020",
			expirationDate: "07 May, 2023",
		},
		{
			individualName: "John Doe",
			courseName: "Leadership 020",
			expirationDate: "07 May, 2023",
		},
		{
			individualName: "John Doe",
			courseName: "Leadership 020",
			expirationDate: "07 May, 2023",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData }),
		{ mainSearch, isFound, getDynamicLogger } = useCouponStore(),
		coupons = useCouponStore(),
		courses = useCourseStore(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(coupons?.["usage"]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFound, mainSearch, coupons?.["usage"]]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/course/manage-coupon/usage?coupon=${incomingState?._id}`,
			null,
			d => {
				getDynamicLogger(d, "usage");
				let data = d?.data?.totalDocs || d?.data?.data?.totalDocs || 0;
				setNoOfUsers(data);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [incomingState]);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall(
			"get",
			`/api/v1/course/manage-coupon/usage?coupon=${incomingState?._id}&limit=${
				datum?.limit * datum?.nextPage
			}`,
			null,
			d => getDynamicLogger(d, "usage")
		);
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<div>
				<div className=" tw-overflow-x-scroll">
					<table className="tw-min-w-full tw-min-h-full tw-table-auto">
						<thead>
							<tr>
								{Headers?.map(item => (
									<th className="tw-text-[#013467] tw-text-left tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
										{item}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{currentItems?.map((item, i) => (
								<tr
									style={{
										borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
									}}
									key={i}>
									<td className="tableText">
										{item?.user?.firstName} {item?.user?.lastName}
									</td>
									<td className="tableText">
										{
											courses?.data?.docs?.find(
												it => it?._id === incomingState?.course
											)?.title
										}
									</td>
									<td className="tableText">
										{moment(item?.createdAt).format("DD MMM, YYYY")}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="tw-mt-10 tw-flex tw-justify-center tw-w-44 tw-items-center tw-mx-auto">
						<LoadMore
							next={datum?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default CouponsTable;
