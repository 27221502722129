import React, { useEffect, useRef, useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Img from "../../assets/Screenshot (482) 1.png";
import Vid from "../../assets/Screenshot (610) 1.png";
import Pie from "../../assets/Pie Blue.svg";
import Avatar from "../../assets/new-avatar.svg";
import Edit from "../../assets/edit-2.svg";
import Duplicate from "../../assets/document-copy.svg";
import Add from "../../assets/add-square.svg";
import Delete from "../../assets/trash.svg";
import Down from "../../assets/arrow-up.svg";
import Up from "../../assets/arrowup.svg";
import { MainBtn } from "../../components/button";
import Text from "../../assets/Text.svg";
import Image from "../../assets/Image (1).svg";
import Video from "../../assets/Video (1).svg";
import Chart from "../../assets/Chart.svg";
import List from "../../assets/Listing.svg";
import Quote from "../../assets/Quote.svg";
import Statement from "../../assets/Statement.svg";
import Module from "../../assets/Module Asesment.svg";
import DeleteIcon from "../../assets/deletenew.svg";
import LineChart from "../../assets/linechart.png";
import BarChart from "../../assets/barchart.png";
import StatementA from "../../assets/statementa.svg";
import StatementB from "../../assets/statementb.svg";
import AvatarQuote from "../../assets/avatarquote.svg";
import Multiple from "../../assets/Variant2.svg";
import Single from "../../assets/False.svg";
import { TextArea, TextInput } from "../../components/inputs";
import { ContentWriteup } from "./[id]";
import {
	PieChart,
	BarChart as BarMainChart,
	LineChart as LineMainChart,
} from "../../components/charts/area";

const SingleTemplate = () => {
	return (
		<div>
			<MainContainer>
				<MainHeader text="Template" small={"Template"} />
				<div className="tw-w-full tw-bg-white tw-relative tw-rounded-3xl tw-p-4">
					<MainTemplate />
				</div>
			</MainContainer>
		</div>
	);
};

const TemplateBox = ({ children }) => {
	return (
		<div className="tw-border-[0.5px] tw-relative tw-border-secondary tw-rounded-lg tw-p-4 tw-w-full">
			{children}
		</div>
	);
};

export const MainTemplate = ({ onUpdate, datum }) => {
	const [hoveredButton, setHoveredButton] = useState(null);
	const [editSection, setEditSection] = useState(null);
	const [editSectionIndex, setEditSectionIndex] = useState(null);
	const [activeLink, setActiveLink] = useState(null);
	const [questType, setQuestType] = useState("multiple");
	const [edit, setEdit] = useState(false);
	const [active, setActive] = useState(null);
	const defaultList = [
			{
				type: "text",
				title: "Header 1",
				body: "Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, ",
			},
			{
				type: "list",
				arr: [
					"Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo in.",
					"Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo in.",
					"Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo in.",
				],
			},
			{
				type: "image",
				img: Img,
			},
			{
				type: "video",
				img: Vid,
			},
			{
				type: "pie-chart",
				img: Pie,
			},
			{
				type: "line-chart",
				img: LineChart,
			},
			{
				type: "bar-chart",
				img: BarChart,
			},
			{
				type: "quote",
				avatar: Avatar,
				author: "Jumai Faith",
				text: "Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia v",
			},
			{
				type: "statement-left",
				text: "Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, ",
			},
			{
				type: "statement-center",
				text: "Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, ",
			},
			{
				type: "question",
			},
		],
		[lists, setLists] = useState([]);

	useEffect(() => {
		if (
			datum &&
			datum?.moduleSection &&
			Array.isArray(datum?.moduleSection) &&
			datum?.moduleSection?.length > 0
		)
			setLists(datum?.moduleSection);
	}, [datum]);

	// console.log({ datum });

	const inputs = [
		{
			name: "text",
			type: "text",
			icon: Text,
			show: true,
		},
		{
			name: "list",
			type: "list",
			icon: List,
			show: true,
		},
		{
			name: "image",
			type: "image",
			icon: Image,
			show: true,
		},
		{
			name: "video",
			type: "video",
			icon: Video,
			show: true,
		},
		{
			name: "chart",
			type: "chart",
			icon: Chart,
			children: [
				{
					name: "Pie chart",
					type: "pie-chart",
				},
				{
					name: "Line chart",
					type: "line-chart",
				},
				{
					name: "Bar chart",
					type: "bar-chart",
				},
			],
			show: true,
		},
		{
			name: "quote",
			type: "quote",
			icon: Quote,
			show: true,
		},
		{
			name: "statement",
			type: "statement",
			icon: Statement,
			children: [
				{
					icon: StatementA,
					type: "statement-center",
				},
				{
					icon: StatementB,
					type: "statement-left",
				},
			],
			show: true,
		},
		{
			name: "knowledge check",
			type: "question",
			icon: Statement,
			children: [
				{
					icon: Multiple,
					type: "question",
					label: "Multiple Choice",
				},
				{
					icon: Single,
					type: "question",
					label: "Single Choice",
				},
			],
			show: false,
		},
		{
			name: "module assessment",
			type: "question",
			icon: Module,
			show: false,
		},
	];
	const handleInputClick = inputType => {
		const clicked = inputs.find(input => input.type === inputType);
		const index = inputs.findIndex(input => input.type === inputType);
		if (clicked?.children && clicked?.children?.length > 0) {
			// If the clicked item has children, toggle its active state
			setActiveLink(prevActiveLink =>
				prevActiveLink === index ? null : index
			);
		} else {
			// For items without children, add to lists as before
			const newItem = defaultList.find(input => input.type === inputType);
			if (newItem) {
				const newListItem = { ...newItem, id: lists.length + 1 };
				setLists(prevLists => [...prevLists, newListItem]);
				// Reset activeLink for non-parent items
				setActiveLink(null);
			}
		}
		window.scrollTo(0, document.body.scrollHeight);
	};
	const handleChildrenType = type => {
		const newItem = defaultList.find(input => input.type === type);
		if (newItem) {
			const newListItem = { ...newItem, id: lists.length + 1 };
			setLists(prevLists => [...prevLists, newListItem]);
		}
		setActiveLink(null);
	};
	const Tooltip = ({ text }) => (
		<div
			style={{
				boxShadow: "0px 4px 14px 0px #0000001A",
			}}
			className="tw-absolute tw-font-semibold tw-whitespace-nowrap tw-z-10 tw-bg-white tw-text-secondary tw-rounded-lg tw-text-xs tw-px-2 tw-py-1 tw-bottom-10 tw-left-1/2 tw-transform tw--translate-x-1/2">
			{text}
		</div>
	);

	const ButtonWithTooltip = ({ src, onClick, tooltipText, buttonType }) => (
		<div className="tw-relative tw-inline-block">
			<img
				src={src}
				onClick={onClick}
				alt={tooltipText}
				className="tw-cursor-pointer"
				onMouseEnter={() => setHoveredButton(buttonType)}
				onMouseLeave={() => setHoveredButton(null)}
			/>
			{hoveredButton === buttonType && <Tooltip text={tooltipText} />}
		</div>
	);

	const handleDelete = index => {
		const newList = lists.filter((_, i) => i !== index);
		setLists(newList);
	};

	const handleMoveUp = index => {
		if (index === 0) return;
		const newList = [...lists];
		[newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
		setLists(newList);
	};

	const handleMoveDown = index => {
		if (index === lists.length - 1) return;
		const newList = [...lists];
		[newList[index + 1], newList[index]] = [newList[index], newList[index + 1]];
		setLists(newList);
	};

	const modalRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = event => {
			// Check if the modal ref exists and if the click is outside the modal
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				setEditSection(null);
				setEditSectionIndex(null);
			}
		};

		// Add event listener when component mounts
		document.addEventListener("mousedown", handleClickOutside);

		// Cleanup the event listener when component unmounts
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [setEditSection, setEditSectionIndex]);

	const handleDuplicate = index => {
		const newList = [...lists];
		newList.splice(index, 0, { ...lists[index], id: lists?.length + 1 });
		setLists(newList);
	};

	// const [avatarImage, setAvatarImage] = useState(AvatarQuote);

	// const handleImageChange = event => {
	// 		const file = event.target.files[0];
	// 		if (file) {
	// 			// Create a FileReader to convert the file to a data URL
	// 			const reader = new FileReader();
	// 			reader.onload = e => {
	// 				// Update the avatar image with the new image
	// 				setAvatarImage(e.target.result);
	// 			};
	// 			reader.readAsDataURL(file);
	// 		}
	// 	}
	let setModifier = d => {
			setLists(prev => {
				let newPrev = [...prev];
				newPrev[editSectionIndex - 1] = d;
				return newPrev;
			});
			setEditSection(null);
			setEditSectionIndex(null);
		},
		currentDatum = lists?.[editSectionIndex - 1];

	useEffect(() => {
		if (onUpdate) setEdit(true);
	}, [onUpdate]);

	// console.log({ lists });

	return (
		<div>
			<h4 className="tw-text-xl tw-font-bold tw-text-secondary satoshi">
				Course Details and Module
			</h4>
			<div className="tw-flex tw-gap-8">
				<div className="tw-w-3/4">
					<div className="tw-w-full tw-mt-5">
						<TemplateBox>
							<h6 className="tw-text-sm tw-font-normal tw-text-secondary">
								Module title
							</h6>
							<h4 className="tw-text-xl tw-font-bold tw-text-secondary">
								{datum?.title || "Module"}
							</h4>
						</TemplateBox>
					</div>
					<div className="tw-mt-8 tw-w-full tw-space-y-4">
						{lists?.map((it, index) => (
							<div
								className="tw-relative tw-pb-12" // Add bottom padding to reserve space
								key={index}>
								<div
									onMouseEnter={() => active !== it && setActive(it)} // Prevent unnecessary updates
									onMouseLeave={() => setActive(null)}>
									<TemplateBox>
										<ContentMainDetails it={it} />
										{active === it && (
											<div
												style={{
													boxShadow: "0px 4px 14px 0px #0000001A",
													transition: "opacity 0.3s ease",
												}}
												className="tw-absolute tw-top-3 tw-right-3 tw-flex tw-space-x-3 tw-bg-white tw-px-4 tw-py-3 tw-rounded-lg tw-shadow-md tw-z-50">
												<ButtonWithTooltip
													src={Edit}
													onClick={() => {
														setEditSection(it?.type);
														setEditSectionIndex(index + 1);
														window.scrollTo(0, 0);
													}}
													tooltipText="Edit Content"
													buttonType="edit"
												/>
												<ButtonWithTooltip
													src={Duplicate}
													onClick={() => handleDuplicate(index)}
													tooltipText="Duplicate Item"
													buttonType="duplicate"
												/>
												<ButtonWithTooltip
													src={Delete}
													onClick={() => handleDelete(index)}
													tooltipText="Delete Item"
													buttonType="delete"
												/>
												{lists?.length > 1 && (
													<>
														{index !== 0 && (
															<ButtonWithTooltip
																src={Up}
																onClick={() => handleMoveUp(index)}
																tooltipText="Move Up"
																buttonType="up"
															/>
														)}
														{index !== lists?.length - 1 && (
															<ButtonWithTooltip
																src={Down}
																onClick={() => handleMoveDown(index)}
																tooltipText="Move Down"
																buttonType="down"
															/>
														)}
													</>
												)}
											</div>
										)}
									</TemplateBox>
								</div>
							</div>
						))}
						<div className="tw-mt-8">
							{edit ? (
								<MainBtn
									text={"Continue"}
									onClick={() => {
										if (onUpdate) {
											if (lists?.length > 0) onUpdate(lists);
										}
									}}
								/>
							) : (
								<MainBtn onClick={() => setEdit(true)} text={"Edit Template"} />
							)}
						</div>
					</div>
				</div>
				<div className="tw-w-1/4">
					<div className="tw-min-h-96 tw-flex tw-justify-center">
						<div className="tw-p-5 tw-bg-[#EDF6FF] tw-rounded-lg">
							<div className="tw-w-28 tw-space-y-4 tw-mx-auto">
								{inputs
									?.filter(it => it?.show)
									?.map((input, i) => (
										<div
											onClick={() => handleInputClick(input?.type)}
											className="tw-w-full tw-relative tw-cursor-pointer tw-p-4 tw-bg-white tw-rounded-lg"
											key={i}>
											<img src={input?.icon} alt="" className="tw-mx-auto" />
											<p className="tw-text-base tw-capitalize tw-font-medium tw-text-secondary tw-text-center">
												{input?.name}
											</p>
											{activeLink === i && input?.children?.length > 0 && (
												<div
													style={{
														boxShadow: "0px 4px 14px 0px #0000001A",
													}}
													className="tw-absolute tw-w-48 tw--left-28 tw-py-4 tw-px-4 tw-top-0 tw-bg-white tw-rounded-lg tw-text-xs tw-text-secondary tw-transform tw--translate-x-1/2">
													{input?.type === "chart" && (
														<div>
															{input.children.map((child, childIndex) => (
																<div
																	onClick={() =>
																		handleChildrenType(child?.type)
																	}
																	key={childIndex}
																	className="tw-flex gap-3 tw-h-10 tw-items-center tw-border-b-[0.5px] tw-border-[#0000001A]">
																	{child?.icon && (
																		<img src={child?.icon} alt="" />
																	)}
																	<p className="tw-text-base tw-font-medium tw-text-secondary satoshi tw-capitalize">
																		{child?.name}
																	</p>
																</div>
															))}
														</div>
													)}
													{input?.type === "statement" && (
														<div>
															{input?.children?.map(child => (
																<img
																	onClick={() =>
																		handleChildrenType(child?.type)
																	}
																	src={child?.icon}
																	alt=""
																	srcset=""
																/>
															))}
														</div>
													)}
													{input?.type === "question" && (
														<div>
															{input?.children?.map(ch => (
																<div
																	onClick={() => handleChildrenType(ch?.type)}
																	className="tw-flex tw-border-b-[0.5px] tw-border-[#0000001A] tw-h-10 tw-gap-3 tw-items-center tw-w-full">
																	<img src={ch?.icon} alt="" className="" />
																	<h6 className="tw-text-base tw-font-medium tw-text-secondary">
																		{ch?.label}
																	</h6>
																</div>
															))}
														</div>
													)}
												</div>
											)}
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
			{editSection && (
				<div
					ref={modalRef}
					className="tw-absolute tw-z-[1000] tw-top-11 tw-right-56 tw-w-[420px] tw-min-h-[80vh] tw-rounded-xl tw-p-5 tw-bg-[#EDF6FF]">
					<div className="tw-bg-white tw-p-5 tw-w-full tw-min-h-[80vh] tw-overflow-scroll noScroll">
						{editSection && editSection === "text" && (
							<EditTextData datum={currentDatum} setModifier={setModifier} />
						)}
						{editSection && editSection === "list" && (
							<EditListData datum={currentDatum} setModifier={setModifier} />
						)}
						{editSection && editSection === "image" && (
							<EditImageVideoData
								datum={currentDatum}
								setModifier={setModifier}
								type="Image"
							/>
						)}
						{editSection && editSection === "video" && (
							<EditImageVideoData
								datum={currentDatum}
								setModifier={setModifier}
								type="Video"
							/>
						)}
						{editSection &&
							["pie-chart", "bar-chart", "line-chart"]?.includes(
								editSection
							) && (
								<EditChartData
									datum={currentDatum}
									setModifier={setModifier}
									type={editSection?.split("-")?.[0]}
								/>
							)}
						{/* {editSection && editSection === "pie-chart" && (
							<div>
								<h4 className="tw-text-lg tw-font-bold tw-text-secondary satoshi">
									Edit Pie Chart
								</h4>
								<div className="tw-space-y-4 tw-mt-5">
									<TemplateBox>
										<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
											Chart Title
										</h6>
										<input
											type="text"
											placeholder="Heading"
											name=""
											className="tw-h-14 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-xl satoshi tw-font-medium"
											id=""
										/>
									</TemplateBox>
									<TemplateBox>
										<div className="tw-grid tw-grid-cols-2 tw-gap-2">
											<div>
												<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
													Items Label
												</h6>
												<input
													type="text"
													placeholder="Heading"
													name=""
													className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
													id=""
												/>
											</div>
											<div>
												<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
													Values Lable
												</h6>
												<input
													type="text"
													placeholder="Heading"
													name=""
													className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
													id=""
												/>
											</div>
										</div>
									</TemplateBox>
									<TemplateBox>
										<div className="tw-grid tw-grid-cols-2 tw-gap-3">
											<h5 className="tw-text-sm tw-font-bold tw-text-secondary">
												Items
											</h5>
											<h5 className="tw-text-sm tw-font-bold tw-text-secondary">
												Values
											</h5>
										</div>
										<div
											className="tw-mt-5 tw-border-b-[0.5px] tw-border-b-[#0000001A] tw-grid tw-grid-cols-2 tw-gap-3
                        ">
											<input
												type="text"
												placeholder="New Item"
												name=""
												className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
												id=""
											/>
											<input
												type="text"
												placeholder="New Value"
												name=""
												className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
												id=""
											/>
										</div>
									</TemplateBox>
								</div>
							</div>
						)}
						{editSection && editSection === "line-chart" && (
							<div>
								<h4 className="tw-text-lg tw-font-bold tw-text-secondary satoshi">
									Edit Line Chart
								</h4>
								<div className="tw-space-y-4 tw-mt-5">
									<TemplateBox>
										<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
											Chart Title
										</h6>
										<input
											type="text"
											placeholder="Heading"
											name=""
											className="tw-h-14 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-xl satoshi tw-font-medium"
											id=""
										/>
									</TemplateBox>
									<TemplateBox>
										<div className="tw-grid tw-grid-cols-2 tw-gap-2">
											<div>
												<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
													Items Label
												</h6>
												<input
													type="text"
													placeholder="Heading"
													name=""
													className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
													id=""
												/>
											</div>
											<div>
												<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
													Values Lable
												</h6>
												<input
													type="text"
													placeholder="Heading"
													name=""
													className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
													id=""
												/>
											</div>
										</div>
									</TemplateBox>
									<TemplateBox>
										<div className="tw-grid tw-grid-cols-2 tw-gap-3">
											<h5 className="tw-text-sm tw-font-bold tw-text-secondary">
												Items
											</h5>
											<h5 className="tw-text-sm tw-font-bold tw-text-secondary">
												Values
											</h5>
										</div>
										<div
											className="tw-mt-5 tw-border-b-[0.5px] tw-border-b-[#0000001A] tw-grid tw-grid-cols-2 tw-gap-3
                        ">
											<input
												type="text"
												placeholder="New Item"
												name=""
												className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
												id=""
											/>
											<input
												type="text"
												placeholder="New Value"
												name=""
												className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
												id=""
											/>
										</div>
									</TemplateBox>
								</div>
							</div>
						)}
						{editSection && editSection === "bar-chart" && (
							<div>
								<h4 className="tw-text-lg tw-font-bold tw-text-secondary satoshi">
									Edit Bar Chart
								</h4>
								<div className="tw-space-y-4 tw-mt-5">
									<TemplateBox>
										<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
											Chart Title
										</h6>
										<input
											type="text"
											placeholder="Heading"
											name=""
											className="tw-h-14 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-xl satoshi tw-font-medium"
											id=""
										/>
									</TemplateBox>
									<TemplateBox>
										<div className="tw-grid tw-grid-cols-2 tw-gap-2">
											<div>
												<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
													Items Label
												</h6>
												<input
													type="text"
													placeholder="Heading"
													name=""
													className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
													id=""
												/>
											</div>
											<div>
												<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
													Values Lable
												</h6>
												<input
													type="text"
													placeholder="Heading"
													name=""
													className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
													id=""
												/>
											</div>
										</div>
									</TemplateBox>
									<TemplateBox>
										<div className="tw-grid tw-grid-cols-2 tw-gap-3">
											<h5 className="tw-text-sm tw-font-bold tw-text-secondary">
												Items
											</h5>
											<h5 className="tw-text-sm tw-font-bold tw-text-secondary">
												Values
											</h5>
										</div>
										<div
											className="tw-mt-5 tw-border-b-[0.5px] tw-border-b-[#0000001A] tw-grid tw-grid-cols-2 tw-gap-3
                        ">
											<input
												type="text"
												placeholder="New Item"
												name=""
												className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
												id=""
											/>
											<input
												type="text"
												placeholder="New Value"
												name=""
												className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
												id=""
											/>
										</div>
									</TemplateBox>
								</div>
							</div>
						)} */}
						{editSection && editSection?.includes("statement") && (
							<EditStatementData
								datum={currentDatum}
								setModifier={setModifier}
							/>
						)}
						{editSection && editSection === "quote" && (
							<EditQuoteData datum={currentDatum} setModifier={setModifier} />
						)}
						{editSection && editSection === "question" && (
							<div>
								<h4 className="tw-text-lg tw-font-bold tw-text-secondary satoshi">
									Edit Knowledge Check
								</h4>
								<div className="tw-space-y-4 tw-mt-4">
									<TemplateBox>
										<h6 className="tw-text-sm tw-font-medium tw-text-secondary">
											Question type
										</h6>
										<select
											name=""
											id=""
											onChange={e => setQuestType(e?.target?.value)}
											className="tw-mt-2 tw-border-none tw-h-10 tw-w-full">
											<option
												className="tw-text-sm tw-text-secondary tw-font-medium"
												value="multiple"
												selected>
												Multiple Choice
											</option>
											<option
												className="tw-text-sm tw-text-secondary tw-font-medium"
												value="single"
												selected>
												Single Choice
											</option>
										</select>
									</TemplateBox>
									<TemplateBox>
										<h6 className="tw-text-sm tw-font-medium tw-text-secondary">
											Question
										</h6>
										<input
											type="text"
											placeholder="Enter your question"
											name=""
											className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
											id=""
										/>
									</TemplateBox>
									<TemplateBox>
										<div className="tw-flex tw-justify-between tw-items-center">
											<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
												Correct
											</h6>
											<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
												Choice text
											</h6>
										</div>
										<div className="tw-mt-4 tw-space-y-3">
											<div className="tw-border-b-[0.5px] tw-border-[#0000001A] tw-h-10 tw-flex tw-items-center tw-justify-between">
												<input
													type={questType === "single" ? "radio" : "checkbox"}
													name=""
													className="tw-h-4 tw-w-4 tw-border-[0.84px] tw-border-[#0269D0]"
													id=""
												/>
												<input
													type="text"
													placeholder="Choice 1"
													name=""
													className="tw-h-8 tw-mb-1 placeholder:tw-text-right tw-w-3/4 tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
													id=""
												/>
											</div>
											<div className="tw-border-b-[0.5px] tw-border-[#0000001A] tw-h-10 tw-flex tw-items-center tw-justify-between">
												<input
													type={questType === "single" ? "radio" : "checkbox"}
													name=""
													className="tw-h-4 tw-w-4 tw-border-[0.84px] tw-border-[#0269D0]"
													id=""
												/>
												<input
													type="text"
													placeholder="Choice 1"
													name=""
													className="tw-h-8 tw-mb-1 placeholder:tw-text-right tw-w-3/4 tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
													id=""
												/>
											</div>
											<div className="tw-border-b-[0.5px] tw-border-[#0000001A] tw-h-10 tw-flex tw-items-center tw-justify-between">
												<input
													type={questType === "single" ? "radio" : "checkbox"}
													name=""
													className="tw-h-4 tw-w-4 tw-border-[0.84px] tw-border-[#0269D0]"
													id=""
												/>
												<input
													type="text"
													placeholder="Choice 1"
													name=""
													className="tw-h-8 tw-mb-1 placeholder:tw-text-right tw-w-3/4 tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
													id=""
												/>
											</div>
										</div>
									</TemplateBox>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default SingleTemplate;

export const EditTextData = ({ setModifier, datum }) => {
	let init = {},
		[state, setState] = useState(init),
		textChange = ({ target: { value, name } }) => {
			setState(prev => {
				return { ...prev, [name]: value };
			});
		};

	useEffect(() => {
		if (datum) setState(datum);
	}, [datum]);
	// console.log({ datum });

	return (
		<>
			<div>
				<h4 className="tw-text-lg tw-font-bold tw-text-secondary satoshi">
					Edit Paragraph with heading
				</h4>
				<div className="tw-space-y-4 tw-mt-3">
					<TemplateBox>
						<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
							Heading
						</h6>
						{/* <input
							type="text"
							placeholder="Heading"
							name="title"
							className="tw-h-14 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-xl satoshi tw-font-medium"
							id=""
							onChange={textChange}
							value={state?.title || datum?.title}
							/> */}
						<TextInput
							// label={"Name of Course"}
							onChange={textChange}
							name={"title"}
							value={state?.title || datum?.title}
						/>
					</TemplateBox>
					<TemplateBox>
						<h6 className="tw-text-sm tw-font-bold tw-text-secondary">Text</h6>
						{/* <textarea
							name="body"
							onChange={textChange}
							value={state?.body || datum?.body}
							className="tw-h-32 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
							id=""></textarea> */}
						<TextArea
							// label={"Course overview"}
							value={state?.body}
							onChange={textChange}
							name="body"
							setState={e =>
								setState(prev => {
									let newPrev = { ...prev, body: e };
									return newPrev;
								})
							}
							className="tw-max-h-72"
						/>
					</TemplateBox>
					<div className="tw-flex tw-justify-center tw-mt-6">
						<MainBtn
							onClick={() => {
								setModifier(state);
							}}
							text={"Submit"}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export const EditListData = ({ setModifier, datum }) => {
	let init = [],
		[state, setState] = useState(init),
		textChange = ({ target: { value, name } }, index) => {
			setState(prev => {
				let newPrev = [...prev];
				newPrev[index] = value;
				return newPrev;
			});
		};

	useEffect(() => {
		if (datum) setState(datum?.arr);
	}, [datum]);

	const handleDelete = index => {
		setState(prev => {
			let newList = [...prev.filter((_, i) => i !== index)];
			return newList;
		});
	};
	// console.log({ datum });

	return (
		<>
			<div className="">
				<h4 className="tw-text-lg tw-font-bold tw-text-secondary satoshi">
					Edit Numbered List
				</h4>
				<div className="tw-space-y-4 tw-mt-4">
					{state?.map((it, i) => (
						<TemplateBox key={i}>
							<div className="tw-flex tw-justify-between tw-items-center">
								<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
									List Item {i + 1}.
								</h6>
								<img
									onClick={() => handleDelete(i)}
									src={DeleteIcon}
									alt=""
									className="tw-cursor-pointer"
								/>
							</div>
							{/* <h6 className="tw-text-sm tw-mt-3 tw-font-bold tw-text-secondary">
							</h6> */}
							{/* <textarea
								name=""
								className="tw-h-32 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-py-2 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
								id=""></textarea> */}
							<TextArea
								// label={"Course overview"}
								value={it}
								onChange={textChange}
								name="body"
								setState={e =>
									setState(prev => {
										let newPrev = [...prev];
										newPrev[i] = e;
										return newPrev;
									})
								}
								className="tw-max-h-72"
							/>
						</TemplateBox>
					))}
				</div>
				<div className="tw-flex tw-justify-end tw-mt-6">
					<MainBtn
						text={"Add Item"}
						onClick={() => {
							setState(prev => {
								return [...prev, ""];
							});
						}}
					/>
				</div>
				<div className="tw-flex tw-justify-center tw-mt-6">
					<MainBtn
						onClick={() => {
							setModifier({ ...datum, arr: state });
						}}
						text={"Submit"}
					/>
				</div>
			</div>
		</>
	);
};

export const EditQuoteData = ({ setModifier, datum }) => {
	let init = {},
		[state, setState] = useState(init),
		textChange = ({ target: { value, name } }) => {
			setState(prev => {
				return { ...prev, [name]: value };
			});
		};

	useEffect(() => {
		if (datum) setState(datum);
	}, [datum]);

	function embedFilenameInBase64(file, callback) {
		const reader = new FileReader();
		reader.onload = () => {
			// Get the Base64 string from the FileReader result
			const base64 = reader.result;

			// Embed the filename into the Base64 string (e.g., using a custom format)
			const base64WithFilename = `${base64};name=${file?.name}`;

			// Pass the modified Base64 string to the callback
			callback(base64WithFilename);
		};
		reader.readAsDataURL(file);
	}

	const handleImageChange = (event, name) => {
		const file = event.target.files[0];
		if (file) {
			// // Create a FileReader to convert the file to a data URL
			// const reader = new FileReader();
			// reader.onload = e => {
			// 	// Update the avatar image with the new image
			// 	setState(prev => {
			// 		return { ...prev, [name]: e?.target?.result };
			// 	});
			// };
			// reader.readAsDataURL(file);
			embedFilenameInBase64(file, base64WithFilename => {
				// console.log("Base64 with embedded filename:", base64WithFilename);
				setState(prev => {
					return { ...prev, [name]: base64WithFilename };
				});
			});
		}
	};
	// console.log({ datum });

	return (
		<>
			<div>
				<h4 className="tw-text-lg tw-font-bold tw-text-secondary satoshi">
					Edit Quote
				</h4>
				<div className="tw-space-y-4 tw-mt-4">
					<TemplateBox>
						<h6 className="tw-text-sm tw-font-medium tw-text-secondary">
							Quote
						</h6>
						{/* <textarea
							name=""
							className="tw-h-32 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
							id=""></textarea> */}
						<TextArea
							// label={"Course overview"}
							value={state?.text}
							onChange={textChange}
							name="text"
							setState={e =>
								setState(prev => {
									let newPrev = { ...prev, text: e };
									return newPrev;
								})
							}
							className="tw-max-h-72"
						/>
					</TemplateBox>
					<TemplateBox>
						<h6 className="tw-text-sm tw-font-medium tw-text-secondary">
							Name
						</h6>
						{/* <input
							type="text"
							placeholder="Name"
							name=""
							className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-xl satoshi tw-font-medium"
							id=""
						/> */}
						<TextInput
							// label={"Name of Course"}
							onChange={textChange}
							name={"author"}
							value={state?.author || datum?.author}
						/>
					</TemplateBox>
					<TemplateBox>
						<h6 className="tw-text-sm tw-font-medium tw-text-secondary">
							Avatar
						</h6>
						<div className="tw-mt-2 tw-flex tw-justify-between tw-items-center">
							<img
								src={state?.avatar || AvatarQuote}
								alt="Avatar"
								className="tw-w-16 tw-h-16 tw-object-cover tw-rounded-full"
							/>
							<label
								htmlFor="avatar-upload"
								className="tw-text-sm tw-font-bold tw-text-[#0269D0] tw-underline tw-cursor-pointer">
								Edit
								<input
									type="file"
									id="avatar-upload"
									accept="image/*"
									onChange={e => handleImageChange(e, "avatar")}
									className="tw-hidden"
								/>
							</label>
						</div>
					</TemplateBox>
					<div className="tw-flex tw-justify-center tw-mt-6">
						<MainBtn
							onClick={() => {
								setModifier(state);
							}}
							text={"Submit"}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export const EditStatementData = ({ setModifier, datum }) => {
	let init = {},
		[state, setState] = useState(init),
		textChange = ({ target: { value, name } }) => {
			setState(prev => {
				return { ...prev, [name]: value };
			});
		};

	useEffect(() => {
		if (datum) setState(datum);
	}, [datum]);
	// console.log({ datum });

	return (
		<>
			<div>
				<h4 className="tw-text-lg tw-font-bold tw-text-secondary satoshi">
					Edit Statement
				</h4>
				<div className="tw-space-y-4 tw-mt-4">
					<TemplateBox>
						<h6 className="tw-text-sm tw-font-medium tw-text-secondary">
							Statement
						</h6>
						{/* <textarea
							name=""
							className="tw-h-32 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
							id=""></textarea> */}
						<TextArea
							// label={"Course overview"}
							value={state?.text}
							onChange={textChange}
							name="text"
							setState={e =>
								setState(prev => {
									let newPrev = { ...prev, text: e };
									return newPrev;
								})
							}
							className="tw-max-h-72"
						/>
					</TemplateBox>
					<div className="tw-flex tw-justify-center tw-mt-6">
						<MainBtn
							onClick={() => {
								setModifier(state);
							}}
							text={"Submit"}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export const EditImageVideoData = ({ setModifier, datum, type = "Image" }) => {
	let init = {},
		[state, setState] = useState(init),
		textChange = ({ target: { value, name } }) => {
			setState(prev => {
				return { ...prev, [name]: value };
			});
		};

	useEffect(() => {
		if (datum) setState(datum);
	}, [datum]);

	const handleImageChange = (event, name) => {
		const file = event.target.files[0];
		if (file) {
			// Create a FileReader to convert the file to a data URL
			const reader = new FileReader();
			reader.onload = e => {
				// Update the avatar image with the new image
				setState(prev => {
					return { ...prev, [name]: e?.target?.result };
				});
			};
			reader.readAsDataURL(file);
		}
	};
	// console.log({ datum });

	return (
		<>
			<div className="">
				<h4 className="tw-text-lg tw-font-bold tw-text-secondary satoshi">
					Edit {type}
				</h4>
				<div className="tw-space-y-4 tw-mt-4">
					<TemplateBox>
						<div className="tw-flex tw-justify-between tw-items-center">
							<h6 className="tw-text-sm tw-font-bold tw-text-secondary">1.</h6>
							<img
								src={DeleteIcon}
								alt=""
								className="tw-cursor-pointer"
								onClick={() => {
									setState(prev => {
										return { ...prev, img: datum?.img || null };
									});
								}}
							/>
						</div>
						<div className="tw-mt-2 tw-flex tw-justify-between tw-items-centern">
							<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
								Man Preach
							</h6>
							<label
								htmlFor="avatar-upload"
								className="tw-text-sm tw-font-bold tw-text-[#0269D0] tw-underline tw-cursor-pointer">
								Edit
								<input
									type="file"
									id="avatar-upload"
									accept={type !== "Image" ? "video/*" : "image/*"}
									onChange={e => handleImageChange(e, "img")}
									className="tw-hidden"
								/>
							</label>
						</div>
						{type !== "Image" ? (
							<>
								<video
									src={state?.img || (type === "Image" ? Img : Vid)}
									alt=""
									className="tw-my-3 tw-rounded-lg"
									muted
									autoPlay
									poster={Vid}
								/>
							</>
						) : (
							<img
								src={state?.img || (type === "Image" ? Img : Vid)}
								alt=""
								className="tw-my-3 tw-rounded-lg"
							/>
						)}
						{/* <input
							type="text"
							placeholder="Type caption here"
							name=""
							className="tw-h-14 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
							id=""
							/> */}
						<TextInput
							// label={"Name of Course"}
							placeholder="Type caption here"
							onChange={textChange}
							name={"caption"}
							value={state?.caption || datum?.caption}
						/>
					</TemplateBox>
					<div className="tw-flex tw-justify-center tw-mt-6">
						<MainBtn
							onClick={() => {
								setModifier(state);
							}}
							text={"Submit"}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export const EditChartData = ({ setModifier, datum, type = "Line" }) => {
	let init = {
			data: [{}],
		},
		[state, setState] = useState(init),
		textChange = ({ target: { value, name } }, index) => {
			setState(prev => {
				let newPrev = { ...prev };
				if (!index) newPrev[name] = value;
				else {
					let newIndex = index === "0" ? 0 : Number(index);
					let clonedObject = { ...newPrev?.data?.[newIndex] };
					clonedObject = { ...clonedObject, [name]: value };

					newPrev.data = [
						...newPrev.data?.map((it, i) =>
							i === newIndex ? clonedObject : it
						),
					];
				}
				return newPrev;
			});
		};

	useEffect(() => {
		if (datum) setState({ ...datum, data: datum?.data ? datum?.data : [{}] });
	}, [datum]);

	// console.log({ datum });

	let addNewData = () => {
			setState(prev => {
				return { ...prev, data: [...prev?.data, {}] };
			});
		},
		handleDeleteRowForMutipleItem = index => {
			setState(prev => {
				let newPrev = { ...prev };
				let newData = [...newPrev?.data];
				newData?.splice(index, 1);
				newPrev.data = newData;
				return newPrev;
			});
		};

	return (
		<>
			<div>
				<h4 className="tw-text-lg tw-font-bold tw-text-secondary satoshi tw-capitalize">
					Edit {type} Chart
				</h4>
				<div className="tw-space-y-4 tw-mt-5">
					<TemplateBox>
						<h6 className="tw-text-sm tw-font-bold tw-text-secondary tw-capitalize">
							{type} Title
						</h6>
						{/* <input
							type="text"
							placeholder="Heading"
							name=""
							className="tw-h-14 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-xl satoshi tw-font-medium"
							id=""
						/> */}
						<TextInput
							// label={"Name of Course"}
							placeholder="Heading"
							onChange={textChange}
							name={"title"}
							value={state?.title || datum?.title}
						/>
					</TemplateBox>
					<TemplateBox>
						<div className="tw-grid tw-grid-cols-2 tw-gap-2">
							<div>
								<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
									Items Label
								</h6>
								{/* <input
									type="text"
									placeholder="Heading"
									name=""
									className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
									id=""
								/> */}
								<TextInput
									// label={"Name of Course"}
									placeholder="Heading"
									onChange={textChange}
									name={"itemLabel"}
									value={state?.itemLabel || datum?.itemLabel}
								/>
							</div>
							<div>
								<h6 className="tw-text-sm tw-font-bold tw-text-secondary">
									Values Label
								</h6>
								{/* <input
									type="text"
									placeholder="Heading"
									name=""
									className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-base satoshi tw-font-medium"
									id=""
								/> */}
								<TextInput
									// label={"Name of Course"}
									placeholder="Heading"
									onChange={textChange}
									name={"valueLabel"}
									value={state?.valueLabel || datum?.valueLabel}
								/>
							</div>
						</div>
					</TemplateBox>
					<TemplateBox>
						<div className="tw-grid tw-grid-cols-2 tw-gap-3">
							<h5 className="tw-text-sm tw-font-bold tw-text-secondary">
								Items
							</h5>
							<h5 className="tw-text-sm tw-font-bold tw-text-secondary">
								Values
							</h5>
						</div>
						{state?.data?.map((list, index) => (
							<div className="tw-mt-5" key={index}>
								<div
									className=" tw-grid tw-grid-cols-2 tw-gap-3
                        ">
									{/* <input
									type="text"
									placeholder="New Item"
									name=""
									className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
									id=""
								/> */}
									<TextInput
										// label={"Name of Course"}
										placeholder="New Item"
										onChange={e => textChange(e, index?.toString())}
										name={"item"}
										value={list?.item}
									/>
									{/* <input
									type="text"
									placeholder="New Value"
									name=""
									className="tw-h-10 tw-w-full tw-border-none tw-mt-2 focus:tw-border-[0.5px] focus:tw-border-secondary tw-px-4 tw-rounded-lg tw-text-secondary tw-text-sm satoshi tw-font-medium"
									id=""
								/> */}
									<TextInput
										// label={"Name of Course"}
										placeholder="New Value"
										onChange={e => textChange(e, index?.toString())}
										name={"value"}
										value={list?.value}
										type="number"
										setState={e =>
											textChange(
												{ target: { value: e, name: "value" } },
												index?.toString()
											)
										}
										noFormat
									/>
								</div>
								{state?.data?.length > 1 && (
									<>
										<div className="tw-flex tw-gap-2 tw-mt-2 tw-items-center tw-justify-end">
											<img
												src={Delete}
												alt=""
												onClick={() => handleDeleteRowForMutipleItem(index)}
												className="tw-cursor-pointer"
											/>
											<h6
												onClick={() => handleDeleteRowForMutipleItem(index)}
												className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
												Delete Data
											</h6>
										</div>
									</>
								)}
							</div>
						))}
						<div className="tw-flex tw-gap-2 tw-my-3 tw-items-center">
							<img
								src={Add}
								alt=""
								className="tw-cursor-pointer"
								onClick={addNewData}
							/>
							<h6
								className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer"
								onClick={addNewData}>
								Add Another Data
							</h6>
						</div>
					</TemplateBox>
					<div className="tw-flex tw-justify-center tw-mt-6">
						<MainBtn
							onClick={() => {
								setModifier(state);
							}}
							text={"Submit"}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export const ContentMainDetails = ({ it }) => {
	return (
		<>
			{it?.type === "text" && (
				<div>
					<h4 className="tw-text-base tw-font-medium tw-text-secondary">
						{it?.title}
					</h4>
					{/* <p className="tw-text-base tw-leading-7 tw-mt-3 tw-font-normal tw-text-secondary">
													{it?.body}
												</p> */}
					{ContentWriteup(
						it?.body,
						"tw-text-base tw-leading-7 tw-mt-3 tw-font-normal tw-text-secondary"
					)}
				</div>
			)}
			{it?.type === "list" && (
				<div>
					<ul className="tw-list-decimal tw-list-inside tw-text-base tw-leading-7 tw-font-normal tw-text-secondary tw-space-y-2">
						{it?.arr?.map((item, i) => (
							<li key={i}>
								{ContentWriteup(
									item,
									"tw-text-base tw-leading-7 tw-mt-3 tw-font-normal tw-text-secondary",
									"span"
								)}
							</li>
						))}
					</ul>
				</div>
			)}
			{["pie-chart"].includes(it?.type) && (
				<>
					<PieChart item={it} />
				</>
			)}
			{["bar-chart"].includes(it?.type) && (
				<>
					<BarMainChart item={it} />
				</>
			)}
			{["line-chart"].includes(it?.type) && (
				<>
					<LineMainChart item={it} />
				</>
			)}
			{["image", "video"].includes(it?.type) &&
				(it?.type === "video" ? (
					<video
						src={it?.img}
						alt=""
						className="tw-w-full tw-h-56"
						muted
						autoPlay
						poster={Vid}
					/>
				) : (
					<img src={it?.img} alt="" className="tw-w-full tw-h-56" />
				))}
			{it?.type === "quote" && (
				<div>
					<img src={it?.avatar} alt="" className="tw-mx-auto tw-h-16" />
					<p className="tw-text-base tw-text-center tw-leading-7 tw-mt-3 tw-font-normal tw-text-secondary">
						<span className="tw-font-bold">Quote:</span> {/* {it?.text} */}
						{ContentWriteup(
							it?.text,
							"tw-font-normal tw-text-secondary tw-text-base tw-text-center tw-leading-7"
						)}
					</p>
					<p className="tw-mt-5 tw-text-center tw-text-base tw-leading-7 tw-font-normal tw-text-secondary">
						{it?.author}
					</p>
				</div>
			)}
			{it?.type === "statement-left" && (
				<div className="">
					<p className="tw-text-base tw-leading-7 tw-font-normal tw-text-secondary">
						<span className="tw-font-bold">Statement:</span>{" "}
						{ContentWriteup(
							it?.text,
							"tw-font-normal tw-text-secondary tw-text-base tw-leading-7",
							"span"
						)}
					</p>
				</div>
			)}
			{it?.type === "statement-center" && (
				<div>
					<span className="tw-font-bold tw-text-base tw-text-secondary">
						Statement:
					</span>{" "}
					<p className="tw-text-base tw-text-center tw-leading-7 tw-font-normal tw-text-secondary">
						{ContentWriteup(
							it?.text,
							"tw-font-normal tw-text-secondary tw-text-base tw-text-center tw-leading-7"
						)}
					</p>
				</div>
			)}
			{it?.type === "question" && (
				<div>
					<input
						type="text"
						name=""
						className="tw-w-full tw-pl-4 tw-h-12 tw-border-b-[0.5px] tw-border-[#00000033] tw-text-secondary tw-text-lg satoshi tw-font-medium"
						placeholder="Enter Question Here"
						id=""
					/>
					<div className="tw-mt-3 tw-w-3/4 tw-space-y-4 tw-rounded-xl tw-p-6 tw-border-[0.5px] tw-border-[#00000033]">
						<div className="tw-relative tw-h-10 tw-w-full">
							<div className="tw-absolute tw-top-4 tw-left-3 tw-h-4 tw-w-4 tw-border tw-border-[#0269D0] tw-rounded-full"></div>
							<input
								type="text"
								name=""
								className="tw-w-full placeholder:tw-text-right tw-pl-10 tw-pr-6 tw-h-12 tw-border-b-[0.5px] tw-border-[#00000033] tw-text-secondary tw-text-lg satoshi tw-font-medium"
								placeholder="Choice 1"
								id=""
							/>
						</div>
						<div className="tw-relative tw-h-10 tw-w-full">
							<div className="tw-absolute tw-top-4 tw-left-3 tw-h-4 tw-w-4 tw-border tw-border-[#0269D0] tw-rounded-full"></div>
							<input
								type="text"
								name=""
								className="tw-w-full placeholder:tw-text-right tw-pl-10 tw-pr-6 tw-h-12 tw-border-b-[0.5px] tw-border-[#00000033] tw-text-secondary tw-text-lg satoshi tw-font-medium"
								placeholder="Choice 1"
								id=""
							/>
						</div>
						<div className="tw-relative tw-h-10 tw-w-full">
							<div className="tw-absolute tw-top-4 tw-left-3 tw-h-4 tw-w-4 tw-border tw-border-[#0269D0] tw-rounded-full"></div>
							<input
								type="text"
								name=""
								className="tw-w-full placeholder:tw-text-right tw-pl-10 tw-pr-6 tw-h-12 tw-border-b-[0.5px] tw-border-[#00000033] tw-text-secondary tw-text-lg satoshi tw-font-medium"
								placeholder="Choice 1"
								id=""
							/>
						</div>
						<div className="tw-relative tw-h-10 tw-w-full">
							<div className="tw-absolute tw-top-4 tw-left-3 tw-h-4 tw-w-4 tw-border tw-border-[#0269D0] tw-rounded-full"></div>
							<input
								type="text"
								name=""
								className="tw-w-full placeholder:tw-text-right tw-pl-10 tw-pr-6 tw-h-12 tw-border-b-[0.5px] tw-border-[#00000033] tw-text-secondary tw-text-lg satoshi tw-font-medium"
								placeholder="Choice 1"
								id=""
							/>
						</div>
					</div>
					<div className="tw-flex tw-justify-center tw-mt-6">
						<MainBtn text={"Submit"} />
					</div>
				</div>
			)}
		</>
	);
};