import React from "react";
import { useCourseData } from "../../pages/dashboard";

const CompletionTable = () => {
	const Headers = ["name", "course", "status"];

	let { courses } = useCourseData();

	if (!courses) return <></>;

	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.map(item => (
								<th className="tw-text-skyblue  tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{courses?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText ">
									{item?.lastName} {item?.firstName}
								</td>
								<td className="tableText  tw-capitalize">
									{item?.course?.title}
								</td>
								<td
									className={`tw-text-sm satoshi tw-p-3 tw-font-normal tw-capitalize ${
										item?.progress?.status === "completed"
											? "tw-text-[#0B6C25]"
											: item?.progress?.status === "in-progress"
											? "tw-text-[#333]"
											: "tw-text-[#E34033]"
									}`}>
									{item?.progress?.status}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default CompletionTable;
