import React, { useEffect, useState } from "react";
import { TextInput } from "../inputs";
import { Button } from "../button";
import moment from "moment";
import useErrorStore from "../../data/stores/errorstore";
import { apiCall } from "../../data/stores/authstore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCouponStore } from "../../data/stores/loggerstore";
import useCourseStore from "../../data/stores/coursestore";

const AddCouponModal = ({ handleClose, handleAdd, state: preState, datum }) => {
	let [state, setState] = useState({}),
		textChange = ({ target: { value, name } }) => {
			setState(prev => {
				let newPrev = { ...prev };
				newPrev[name] = value;
				return newPrev;
			});
		},
		[loading, setLoading] = useState(null),
		navigate = useNavigate(),
		{ returnErrors } = useErrorStore(),
		{ addLogger, updateLogger } = useCouponStore(),
		{ data } = useCourseStore(),
		handleSubmit = async (e, se) => {
			e?.preventDefault();
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				datum ? "put" : "post",
				`/api/v1/course/manage-coupon${datum ? `?coupon=${datum?._id}` : ""}`,
				datum ? state : { coupons: [state] }
			);
			// console.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				if (se) se();
				if (datum) {
					updateLogger(response);
					navigate(-1);
				} else {
					addLogger(response);
				}
				handleClose();
				return;
			}
			setLoading(false);
		},
		makeUpdate = e => {
			if (handleAdd) handleAdd(state);
			else {
				handleSubmit(e);
			}
		};

	useEffect(() => {
		if (preState) setState(preState);
	}, [preState]);

	// console.log({ state, datum });

	return (
		<div>
			<div
				onClick={e => e.target === e.currentTarget && handleClose()}
				className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-20 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
				<div className="lg:tw-w-[30%] tw-w-5/6 tw-mx-auto tw-p-6 tw-bg-[#fff] tw-max-h-[80vh] tw-rounded-xl  noScroll tw-overflow-y-auto">
					<div>
						<h3 className="tw-text-xl tw-font-bold tw-text-[#0269D0]">
							{datum ? "Update Coupon" : "Add Coupon"}
						</h3>
						<form action="" className="tw-mt-6">
							<div className="tw-space-y-4">
								<TextInput
									label={"Coupon code"}
									onChange={textChange}
									value={state?.code || datum?.code}
									name={"code"}
									readOnly={datum}
								/>
								{!handleAdd && (
									<TextInput
										readOnly={datum}
										label={"Course"}
										onChange={textChange}
										value={state?.course || datum?.course}
										name={"course"}
										type={"select"}
										options={data?.docs}
									/>
								)}
								<TextInput
									readOnly={datum}
									label={"Discount type"}
									onChange={textChange}
									value={state?.discountType || datum?.discountType}
									name={"discountType"}
									type={"select"}
									options={[
										{ _id: "fixed", name: "Fixed" },
										{ _id: "percentage", name: "Percentage" },
									]}
								/>
								<TextInput
									label={
										state?.discountType === "fixed"
											? "Fixed Amount"
											: "Percentage"
									}
									value={state?.discount || datum?.discount}
									name={"discount"}
									setState={e => setState({ ...state, discount: e })}
									onChange={textChange}
									type={"number"}
									percentage={state?.discountType !== "fixed"}
									min={1}
									max={state?.discountType !== "fixed" ? 100 : null}
								/>
								<TextInput
									type={"date"}
									label={"Expiry date"}
									onChange={textChange}
									value={
										state?.expirationDate ||
										(datum?.expirationDate
											? moment(datum?.expirationDate).format("YYYY-MM-DD")
											: "")
									}
									name={"expirationDate"}
									min={moment().format("YYYY-MM-DD")}
								/>
								<TextInput
									label={"Maximum usage"}
									value={state?.maximumUsage || datum?.maximumUsage}
									name={"maximumUsage"}
									setState={e => setState({ ...state, maximumUsage: e })}
									onChange={textChange}
									type={"tel"}
									noFormat
								/>
							</div>
							<div className="tw-mt-7">
								{/* <MainBtn text={"Add Coupon"} onClick={makeUpdate} /> */}
								<Button
									css={
										"tw-h-10 tw-px-4 tw-bg-skyblue tw-rounded-3xl tw-text-base satoshi tw-font-medium tw-text-white"
									}
									type="button"
									children={datum ? "Update Coupon" : "Add Coupon"}
									loading={loading}
									onClick={makeUpdate}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddCouponModal;
