import React from "react";

const CourseLogTable = ({ courses, user }) => {
	const Headers = [
		"Name of Course",
		"Progress",
		"No. of Module",
		"Status",
		"Certificate status",
	];
	// data = [
	//   {
	//     name: "John Doe",
	//     progress: "40%",
	//     no_of_module: "20 of 60",
	//     status: "in-progress",
	//     cert_status: "not-earned",
	//   },
	//   {
	//     name: "John Doe",
	//     progress: "40%",
	//     no_of_module: "20 of 60",
	//     status: "in-progress",
	//     cert_status: "not-earned",
	//   },
	//   {
	//     name: "John Doe",
	//     progress: "40%",
	//     no_of_module: "20 of 60",
	//     status: "in-progress",
	//     cert_status: "not-earned",
	//   },
	//   {
	//     name: "John Doe",
	//     progress: "40%",
	//     no_of_module: "20 of 60",
	//     status: "in-progress",
	//     cert_status: "not-earned",
	//   },
	//   {
	//     name: "John Doe",
	//     progress: "40%",
	//     no_of_module: "20 of 60",
	//     status: "locked-out",
	//     cert_status: "not-earned",
	//   },
	//   {
	//     name: "John Doe",
	//     progress: "40%",
	//     no_of_module: "20 of 60",
	//     status: "completed",
	//     cert_status: "not-earned",
	//   },
	// ];

	return (
		<div
			style={{
				border: "1px solid rgba(1, 52, 103, 0.30)",
			}}
			className="tw-overflow-x-auto tw-rounded-2xl tw-mt-8 tw-w-full tw-p-5">
			<table className="tw-min-w-full tw-table-auto">
				{" "}
				<thead>
					<tr>
						{Headers?.map(item => (
							<th className="tw-text-skyblue tw-text-center tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{courses?.map((item, i) => (
						<tr
							style={{
								borderBottom: "0.5px solid rgba(1, 52, 103, 0.30)",
							}}
							key={i}>
							<td className="tableText tw-text-center">{item?.title}</td>
							<td className="tableText tw-text-center">
								{((item?.partakers?.find(ij => ij?._id === user?._id)?.progress
									?.section?.length || 0) /
									item?.partakers
										?.find(ij => ij?._id === user?._id)
										?.module?.reduce?.(
											(ac, i) => (ac += i?.section?.length),
											0
										)) *
									100 || 0}
								%
							</td>
							<td className="tableText tw-text-center">
								{item?.partakers?.find(ij => ij?._id === user?._id)?.progress
									?.module?.length ||
									0 ||
									0}{" "}
								of {item?.module?.length || 0}
							</td>
							<td
								className={`tw-text-sm tw-font-medium satoshi tw-text-center tw-capitalize ${
									item?.partakers?.find(ij => ij?._id === user?._id)?.progress
										?.status === "in-progress"
										? "tw-text-secondary"
										: item?.partakers?.find(ij => ij?._id === user?._id)
												?.progress?.status === "locked-out"
										? "tw-text-[#E34033]"
										: "tw-text-[#0B6C25]"
								}`}>
								{
									item?.partakers?.find(ij => ij?._id === user?._id)?.progress
										?.status
								}
							</td>
							<td className="tw-text-main tw-font-normal tw-text-sm tw-capitalize tw-text-center satoshi">
								{item?.partakers?.find(ij => ij?._id === user?._id)?.progress
									?.certificate
									? "Earned"
									: "Not-earned"}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default CourseLogTable;
