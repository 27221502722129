import React from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs";
import UserTable from "../../components/tables/userstable";

const Students = () => {
  return (
    <div>
      <MainContainer>
        <MainHeader text={"Students"} small={"234 students"} />
        <div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
          <div className="tw-flex tw-gap-4 tw-items-center">
            <h5 className="tw-text-base tw-text-skyblue tw-font-bold satoshi">
              All Students
            </h5>
            <SearchInput />
          </div>
          <div className="tw-mt-8">
            <UserTable />
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default Students;
