import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

const SocketStore = set => ({
	data: null,
	status: "",
	getChats: payload => {
		set({ data: payload?.data || payload }, false, "getSocket");
	},
	getSocket: payload => {
		set({ socket: payload }, false, "getSocket");
	},
	getActive: payload => {
		set({ active: payload?.data || payload }, false, "getActive");
	},
	getNewMessage: payload => {
		set({ message: payload?.data || payload }, false, "getNewMessage");
	},
	getUpdateMessage: payload => {
		set({ updatemessage: payload?.data || payload }, false, "getUpdateMessage");
	},
	resetSocket: () => {
		set({ message: null, updatemessage: null });
	},
	logoutSocket: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const useSocketStore = create(
	devtools(
		persist(SocketStore, {
			name: "socket-data",
		})
	)
);

export default useSocketStore;
