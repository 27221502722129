import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./authstore";

const PlanStore = set => ({
	data: null,
	status: "",
	getSearchPlan: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchPlan"
		);
	},
	getSearch: payload => {
		set({ data: payload?.data || payload }, false, "getSearch");
	},
	resetPlanSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getPlan: payload => {
		set({ data: payload?.data || payload }, false, "getPlan");
	},
	addPlan: payload => {
		let data = payload?.data || payload;

		set(
			state => ({
				data: {
					...state?.data,
					docs: state?.data?.docs ? [...state?.data?.docs, data] : [data],
					totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
					docsTotal: state?.data?.docsTotal ? 1 + state?.data?.docsTotal : 1,
				},
				status: "added",
			}),
			false,
			"addPlan"
		);
	},
	deletePlan: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: DeleteData(state?.data?.docs, data),
					totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
					docsTotal: state?.data?.docsTotal ? state?.data?.docsTotal - 1 : 0,
				},
				status: "deleted",
			}),
			false,
			"deletePlan"
		);
	},
	updatePlan: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: EditData(state?.data?.docs, data),
				},
				status: "updated",
			}),
			false,
			"editPlan"
		);
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutPlan: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const usePlanStore = create(
	devtools(
		persist(PlanStore, {
			name: "plan",
		})
	)
);

export default usePlanStore;
