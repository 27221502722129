import React, { useRef } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { TextArea, TextInput, VideoInput } from "../../components/inputs";
import { MainBtn } from "../../components/button";

const EditCourse = () => {
  return (
    <div>
      <MainContainer>
        <MainHeader text="Courses" small={"Add a new course"} />
        <div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
          <h3 className="tw-text-base tw-font-bold tw-text-myblue satoshi">
            Edit Course ~<span className="tw-font-medium"> Module</span>
          </h3>
          <form action="" className="tw-mt-10 lg:tw-w-1/2">
            <div className="tw-space-y-4">
              <TextInput label={"Name of Course"} />
              <TextArea label={"Course overview"} />
              <VideoInput />
            </div>
            <div className="tw-mt-8 tw-flex tw-gap-5 tw-items-center">
              <MainBtn text={"Continue"} />
              <h6 className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
                Add Another Course
              </h6>
            </div>
          </form>
        </div>
      </MainContainer>
    </div>
  );
};

export default EditCourse;
