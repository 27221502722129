import React, { useContext, useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { MainBtn } from "../../components/button";
import Total from "../../assets/total-coupons.svg";
import Used from "../../assets/coupon-used.svg";
import CouponsTable from "../../components/tables/couponstable";
import AddCouponModal from "../../components/modals/add-coupon";
import { useCouponStore } from "../../data/stores/loggerstore";
import { GlobalState } from "../../data/Context";

const Coupons = () => {
	const [tab, setTab] = useState("active"),
		[modal, setModal] = useState(""),
		tabs = ["active", "deactivated"],
		{ data } = useCouponStore(),
		{ numberWithCommas } = useContext(GlobalState);
	const Arr = [
		{
			name: "Total Coupons",
			bg: "#9191F54D",
			value: data?.allCoupon || "0",
			icon: Total,
		},
		{
			name: "Coupons Used",
			bg: "#FF9DA529",
			value: data?.couponUsed || "0",
			icon: Used,
		},
	];
	return (
		<div>
			<MainContainer>
				<MainHeader text={"Coupons"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					<h3 className="tw-text-base tw-text-secondary tw-font-bold satoshi">
						Coupons Overview
					</h3>
					<div className="tw-flex tw-justify-between tw-mt-5">
						<div className="tw-flex tw-gap-4 tw-items-center">
							{tabs?.map(it => (
								<button
									onClick={() => setTab(it)}
									className={`tw-px-5 tw-h-10 tw-rounded-full tw-text-base tw-font-medium tw-text-[#0269D0] ${
										it === tab ? "tw-bg-[#D9ECFF]" : "tw-bg-transparent"
									}`}>
									{it === "active" ? "Active Coupons" : "Deactivated Coupons"}
								</button>
							))}
						</div>
						<MainBtn text={"Create Coupon"} onClick={() => setModal("add")} />
					</div>
					<div
						style={{
							border: "1px solid #0134674D",
						}}
						className="tw-mt-5 tw-grid tw-grid-cols-2 tw-gap-6 tw-p-6 tw-bg-transparent tw-rounded-xl tw-w-full">
						{Arr?.map(it => (
							<div
								style={{
									background: it?.bg,
								}}
								className="tw-h-24 tw-rounded tw-flex tw-items-center tw-gap-3 tw-pl-6">
								<div className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-shadow-md">
									<img src={it?.icon} alt="" className="" />
								</div>
								<div>
									<h5 className="tw-text-sm tw-font-medium satoshi tw-text-[#0B2239]">
										{it?.name}
									</h5>
									<h1 className="tw-text-3xl tw-font-medium tw-text-[#0B2239]">
										{numberWithCommas(it?.value)}
									</h1>
								</div>
							</div>
						))}
					</div>
					<div className="tw-mt-8">
						<CouponsTable status={tab} />
					</div>
				</div>
			</MainContainer>
			{modal === "add" && <AddCouponModal handleClose={() => setModal("")} />}
		</div>
	);
};

export default Coupons;
