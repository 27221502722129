import React from "react";

const ResultTab = ({ datum }) => {
	// const certs = [
	//   "John Doe",
	//   "John Doe",
	//   "John Doe",
	//   "John Doe",
	//   "John Doe",
	//   "John Doe",
	// ];
	return (
		<div>
			<div className="tw-w-full tw-space-y-4">
				{datum?.partakers
					?.filter(it => it?.progress?.score)
					?.map((item, i) => (
						<div
							key={i}
							style={{
								border: "0.5px solid rgba(37, 37, 37, 0.50)",
							}}
							className="tw-px-4 tw-w-full tw-cursor-pointer tw-rounded-lg">
							<div className="tw-h-12 tw-flex tw-justify-between tw-items-center tw-w-full">
								<small className="tw-text-sm tw-font-medium tw-text-main satoshi">
									{item?.lastName} {item?.firstName}
								</small>
								<small className="tw-text-xs tw-font-medium tw-text-main satoshi">
									{Number(item?.progress?.score || 0).toFixed(2)}%
								</small>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default ResultTab;
