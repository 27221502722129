import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const PageStore = (set) => ({
  visitedPages: [],
  addVisitedPage: (page) =>
    set((state) => ({ visitedPages: [...state.visitedPages, page] })),
});

const usePageStore = create(
  devtools(
    persist(PageStore, {
      name: "page-store",
    })
  )
);

export default usePageStore;
