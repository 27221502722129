import React from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs";
import CertTable from "../../components/tables/certtable";

const Certificates = () => {
  return (
    <div>
      <MainContainer>
        <MainHeader text={"Certificates"} small={"234 Certificates"} />
        <div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
          <div className="tw-flex tw-gap-4 tw-items-center tw-justify-between">
            <h5 className="lg:tw-text-base tw-text-xs tw-text-skyblue tw-font-bold satoshi">
              All Students
            </h5>
            <SearchInput />
          </div>
          <div className="tw-mt-10">
            <CertTable />
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default Certificates;
