import React, { useEffect, useState } from "react";
import { TextInput } from "../inputs";
import { MainBtn } from "../button";
import { useGroupsStore } from "../../data/stores/loggerstore";
import useErrorStore from "../../data/stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/stores/authstore";

const CreateGroupModal = ({ handleClose }) => {
	let [state, setState] = useState({}),
		[members, setMembers] = useState(null),
		[instructors, setInstructors] = useState(null),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ addLogger } = useGroupsStore(),
		{ returnErrors } = useErrorStore(),
		handleSubmit = async e => {
			e?.preventDefault();
			if (!state?.title) return toast.info("Group title is required");
			let newState = state;
			// if (state?.members)
			newState.members = members?.docs
				?.filter(ii => ii?.isChecked)
				?.map(ic => ic?.email);
			// if (state?.instructors)
			newState.instructors = instructors?.docs
				?.filter(ii => ii?.isChecked)
				?.map(ic => ic?.email);

			console.log({ newState });
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				"post",
				`/api/v1/groups/general`,
				newState
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				addLogger(response);
				setState(null);
				handleClose();
				return;
			}
			setLoading(false);
		};

	let InsiderDispay = ({ x, it, setDatum }) => {
		return (
			<div
				key={x}
				className="tw-flex tw-border-b tw-pb-2 tw-justify-between tw-items-center">
				<div className="tw-flex tw-gap-4">
					<input
						onChange={e => {
							setDatum(prev => {
								let prevData = { ...prev },
									dd = prevData?.docs,
									current = dd?.find(ix => ix?.email === it?.email);
								if (e?.target?.checked) {
									current.isChecked = true;
								} else {
									current.isChecked = false;
								}
								dd = dd?.map(ic => (ic?.email === it?.email ? current : ic));
								prevData.docs = dd;
								return prevData;
							});
						}}
						type="checkbox"
						checked={it?.isChecked}
						className="tw-h-4 tw-w-4 tw-border"
						id={it?._id}
					/>
					<label
						className="tw-text-sm tw-text-main satoshi tw-font-normal"
						htmlFor={it?._id}>
						{it?.firstName} {it?.lastName}
					</label>
				</div>
				<h6 className="tw-text-sm first-letter:tw-uppercase tw-text-main satoshi tw-font-normal">
					{it?.dept}
				</h6>
			</div>
		);
	};

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/user/manage-users?privilege=student&pagination=not&keepPrivilege=true`,
			null,
			data => setMembers(data?.data || data)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div
				onClick={e => e.target === e.currentTarget && handleClose()}
				className="tw-fixed tw-z-50 tw-inset-0 tw-bg-black tw-bg-opacity-20 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
				<div className="lg:tw-w-1/2 tw-w-5/6 tw-mx-auto tw-p-6 tw-bg-[#fff] tw-rounded-xl tw-h-1/2 noScroll tw-overflow-y-auto">
					<div className="tw-bg-white tw-rounded-xl tw-w-full tw-p-5">
						<h1 className="tw-text-xl tw-font-medium satoshi tw-text-secondary">
							Create Group
						</h1>
						<form
							action=""
							onSubmit={e => e?.preventDefault()}
							className="tw-mt-5">
							<div className="tw-space-y-4">
								<TextInput
									label={"Name of Group"}
									onChange={textChange}
									value={state?.title}
									name="title"
								/>
								{/* <TextInput
									label={"Add Supervisor"}
									onChange={textChange}
									value={state?.instructors}
									name="instructors"
								/> */}
								<div className="tw-space-y-4 tw-mt-5 tw-hidden">
									<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
										{"Add Supervisor"}
									</p>
									{instructors?.docs?.map((it, x) => (
										<InsiderDispay x={x} it={it} setDatum={setInstructors} />
									))}
								</div>
								{/* <TextInput
									label={"Add Member"}
									onChange={textChange}
									value={state?.members}
									name="members"
								/> */}
								<div className="tw-space-y-4 tw-mt-5">
									<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
										{"Add Member"}
									</p>
									{members?.docs?.map((it, x) => (
										<InsiderDispay x={x} it={it} setDatum={setMembers} />
									))}
								</div>
							</div>
							<div className="tw-mt-6">
								<MainBtn
									text={"Create Group"}
									onClick={handleSubmit}
									loading={loading}
									type="button"
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export const ModalContainer = ({ handleClose, children }) => {
	return (
		<div
			onClick={e => e.target === e.currentTarget && handleClose()}
			className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-20 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
			<div className="lg:tw-w-1/2 tw-w-5/6 tw-mx-auto tw-p-6 tw-bg-[#fff] tw-max-h-96 tw-rounded-xl  noScroll tw-overflow-y-auto">
				{children}
			</div>
		</div>
	);
};

export default CreateGroupModal;
