import axios from "axios";
import { SetAuthToken } from "./Config";
import useAuthStore, { TOKEN, apiCall } from "./stores/authstore";
import { toast } from "react-toastify";
import useErrorStore from "./stores/errorstore";
import useCourseStore from "./stores/coursestore";
import usePlanStore from "./stores/planstore";
import useUserStore from "./stores/userstore";
import useTransactionsStore from "./stores/transactionstore";

const useGenFetcher = () => {
	let { getErrorText, clearErrors } = useErrorStore(),
		{ getUser, getUserFail, getUserLoading } = useAuthStore(),
		{ getCourse, getAllCourse } = useCourseStore(),
		{ getPlan } = usePlanStore(),
		{ getAllTransactionss, getTransactionss } = useTransactionsStore(),
		{ getAllUsers, getUsers } = useUserStore(),
		loadUser = async () => {
			let token = localStorage.getItem(TOKEN);
			if (token) SetAuthToken(token);

			getUserLoading();
			clearErrors();
			try {
				let res = await axios.get(`/api/v1/user`);
				if (res?.data?.data?.isAdmin) {
					getUser(res.data);
					apiCall("get", "/api/v1/course", null, getCourse);
					apiCall("get", "/api/v1/plan", null, getPlan);
					apiCall("get", `/api/v1/course?pagination=not`, null, getAllCourse);
					apiCall(
						"get",
						"/api/v1/user/manage-users?privilege=student",
						null,
						getUsers
					);
					apiCall(
						"get",
						"/api/v1/user/manage-users?privilege=student&pagination=not",
						null,
						getAllUsers
					);
					apiCall("get", `/api/v1/transactions`, null, getTransactionss);
					apiCall(
						"get",
						`/api/v1/transactions?pagination=not`,
						null,
						getAllTransactionss
					);
				} else {
					getUserFail();
					getErrorText("Unauthorized User, Access denied");
				}
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				getUserFail();
				getErrorText(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						""
				);
			}
		};
	return { loadUser };
};

export default useGenFetcher;
