import React, { useContext, useEffect, useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Total from "../../assets/totalcourse.svg";
import Ongoing from "../../assets/ongoing.svg";
import MetricChart from "../../assets/metricschart.svg";
import AsessChart from "../../assets/assess.svg";
import UserChart from "../../assets/userchart.svg";
import { useNavigate } from "react-router-dom";
import useTransactionsStore from "../../data/stores/transactionstore";
import { GlobalState } from "../../data/Context";
import useCourseStore from "../../data/stores/coursestore";
import { CourseDashTable } from "../dashboard";

const Reports = () => {
	let trans = useTransactionsStore(),
		{ numberWithCommas } = useContext(GlobalState);
	const Arr = [
		{
			title: "Revenue Generated",
			icon: Total,
			value: numberWithCommas(
				Number(
					trans?.allData?.docs?.reduce(
						(ac, i) => (ac += Number(i?.amount)),
						0
					) ||
						trans?.data?.docs?.reduce(
							(ac, i) => (ac += Number(i?.amount)),
							0
						) ||
						"0"
				).toFixed(2)
			),
			bg: "#0269d014",
		},
		{
			title: "user payment completions",
			icon: Ongoing,
			value: numberWithCommas(trans?.data?.totalDocs || "0"),
			bg: "#04a7a714",
		},
	];

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Report"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					<h1 className="tw-text-xl satoshi tw-font-bold tw-text-secondary">
						Financial Metrics
					</h1>
					<div
						style={{
							border: "1px solid rgba(1, 52, 103, 0.30)",
							borderRadius: "12px",
						}}
						className="tw-mt-8 lg:tw-h-40 tw-py-6 lg:tw-py-0 tw-px-6 tw-grid lg:tw-grid-cols-2 tw-gap-6 tw-items-center">
						{Arr?.map((item, i) => (
							<div
								key={i}
								style={{
									background: item?.bg,
								}}
								className="tw-h-24 tw-rounded tw-px-5 tw-flex tw-gap-6 tw-items-center">
								<div className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-shadow-md">
									{" "}
									<img src={item?.icon} alt="" className="" />
								</div>
								<div>
									<h5 className="tw-text-sm tw-font-medium satoshi tw-capitalize tw-text-main">
										{item?.title}
									</h5>
									<h2 className="tw-text-3xl tw-font-medium tw-text-main satoshi">
										{item?.value}
									</h2>
								</div>
							</div>
						))}
					</div>
					<div className="tw-mt-10">
						<h3 className="tw-text-xl satoshi tw-font-bold tw-text-secondary">
							Course & Content Metrics
						</h3>
						<CourseMetrics />
					</div>
					<div className="tw-mt-10">
						<h3 className="tw-text-xl satoshi tw-font-bold tw-text-secondary">
							Assessment and Certification
						</h3>
						<AssessmentSection />
					</div>
					<div className="tw-mt-10">
						<h3 className="tw-text-xl satoshi tw-font-bold tw-text-secondary">
							User Progress & Completion
						</h3>
						<UserSection />
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

const CourseMetrics = () => {
	let Arr1 = [
		{
			name: "leadership",
			registered: "34",
		},
		{
			name: "leadership",
			registered: "34",
		},
		{
			name: "leadership",
			registered: "34",
		},
	];
	let course = useCourseStore(),
		[Arr, setArr] = useState(Arr1);

	useEffect(() => {
		if (course?.allCourse) {
			let newArr = [];
			for (let p = 0; p < course?.allCourse?.docs.length; p++) {
				let element = course?.allCourse?.docs[p];

				newArr?.push({
					name: element?.title,
					registered: element?.partakers?.length || 0,
				});
			}
			setArr(
				newArr
					?.filter(o => o?.registered)
					?.sort((a, b) => b?.registered - a?.registered)
					?.slice(0, 3)
			);
		}
	}, [course?.allCourse]);

	const navigate = useNavigate();
	return (
		<div className="tw-mt-4 tw-grid lg:tw-grid-cols-2 tw-gap-8">
			<div
				style={{
					border: "1px solid rgba(1, 52, 103, 0.30)",
					borderRadius: "12px",
				}}
				className="lg:tw-h-48 tw-p-4">
				<div className="tw-flex tw-justify-between tw-items-center">
					<div>
						<h5 className="tw-text-base tw-text-secondary tw-font-medium satoshi">
							Completion and Dropout Rate
						</h5>
					</div>
					<h6
						onClick={() => navigate("/reports/completion-rate")}
						className="tw-text-sm tw-font-medium satoshi tw-text-myblue tw-underline tw-cursor-pointer">
						View
					</h6>
				</div>
				<div className="tw-flex tw-gap-5 tw-items-center tw-justify-between tw-mt-4">
					<img src={MetricChart} alt="" className="" />
					<div className="tw-space-y-2">
						<div className="tw-flex tw-items-center tw-h-full tw-gap-6">
							<div className="tw-flex tw-items-center tw-gap-2">
								<div className="tw-h-4 tw-w-4 tw-bg-[#006A0B]"></div>
								<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
									All Completed Courses{" "}
								</h6>
							</div>
							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={"completed"} />
							</h6>
						</div>
						<div className="tw-flex tw-items-center tw-h-full tw-gap-6">
							<div className="tw-flex tw-items-center tw-gap-2">
								<div className="tw-h-4 tw-w-4 tw-bg-[#FF3739]"></div>
								<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
									Not Completed Courses{" "}
								</h6>
							</div>
							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={"not-completed"} />
							</h6>
						</div>
						<div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-gap-6">
							<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
								Total Registed Courses
							</h6>

							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={"total"} />
							</h6>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					border: "1px solid rgba(1, 52, 103, 0.30)",
					borderRadius: "12px",
				}}
				className="lg:tw-h-48 tw-p-4">
				{" "}
				<div className="tw-flex tw-justify-between tw-items-center">
					<div>
						<h5 className="tw-text-base tw-text-secondary tw-font-medium satoshi">
							Most Popular Courses
						</h5>
					</div>
					<h6 className="tw-text-sm tw-font-medium satoshi tw-text-myblue tw-underline tw-cursor-pointer">
						View
					</h6>
				</div>
				<ul className="tw-mt-4 tw-list-decimal">
					{Arr?.map((item, i) => (
						<li
							style={{
								borderBottom: "0.5px solid rgba(1, 52, 103, 0.30)",
							}}
							className="tw-pb-">
							<div className="tw-flex tw-justify-between tw-px-5">
								<h6 className="tw-text-main tw-pt-1 tw-capitalize tw-text-sm satoshi tw-font-normal">
									{item?.name}
								</h6>
								<h6 className="tw-text-main tw-pt-1 tw-capitalize tw-text-sm satoshi tw-font-normal">
									{item?.registered} Registered
								</h6>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

const AssessmentSection = () => {
	const navigate = useNavigate();
	const Arr = [
		{
			name: "Higher Score",
			registered: <CourseDashTable type={"higher"} />,
		},
		{
			name: "Average Score",
			registered: <CourseDashTable type={"average"} />,
		},
		{
			name: "Lower Score",
			registered: <CourseDashTable type={"lower"} />,
		},
	];
	return (
		<div className="tw-mt-4 tw-grid lg:tw-grid-cols-2 tw-gap-8">
			<div
				style={{
					border: "1px solid rgba(1, 52, 103, 0.30)",
					borderRadius: "12px",
				}}
				className="lg:tw-h-48 tw-p-4">
				<div className="tw-flex tw-justify-between tw-items-center">
					<div>
						<h5 className="tw-text-base tw-text-secondary tw-font-medium satoshi">
							Assessment Score Courses
						</h5>
					</div>
					<h6
						onClick={() => navigate("/reports/assessment")}
						className="tw-text-sm tw-font-medium satoshi tw-text-myblue tw-underline tw-cursor-pointer">
						View
					</h6>
				</div>{" "}
				<ul className="tw-mt-4 tw-list-decimal">
					{Arr?.map((item, i) => (
						<li
							style={{
								borderBottom: "0.5px solid rgba(1, 52, 103, 0.30)",
							}}
							className="tw-pb-">
							<div className="tw-flex tw-justify-between tw-px-5">
								<h6 className="tw-text-main tw-pt-1 tw-capitalize tw-text-sm satoshi tw-font-normal">
									{item?.name}
								</h6>
								<h6 className="tw-text-main tw-pt-1 tw-capitalize tw-text-sm satoshi tw-font-normal">
									{item?.registered}
								</h6>
							</div>
						</li>
					))}
				</ul>
			</div>
			<div
				style={{
					border: "1px solid rgba(1, 52, 103, 0.30)",
					borderRadius: "12px",
				}}
				className="lg:tw-h-48 tw-p-4">
				{" "}
				<div className="tw-flex tw-justify-between tw-items-center">
					<div>
						<h5 className="tw-text-base tw-text-secondary tw-font-medium satoshi">
							Certificates Issued
						</h5>
					</div>
					<h6 className="tw-text-sm tw-font-medium satoshi tw-text-myblue tw-underline tw-cursor-pointer">
						View
					</h6>
				</div>
				<div className="tw-flex tw-gap-5 tw-items-center tw-justify-between tw-mt-4">
					<img src={AsessChart} alt="" className="" />
					<div className="tw-space-y-2">
						<div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-gap-6">
							<div className="tw-flex tw-items-center tw-gap-2">
								<div className="tw-h-4 tw-w-4 tw-bg-[#0269D0]"></div>
								<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
									All Certificate issued{" "}
								</h6>
							</div>
							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={"certificate"} />
							</h6>
						</div>
						<div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-gap-6">
							<div className="tw-flex tw-items-center tw-gap-2">
								<div className="tw-h-4 tw-w-4 tw-bg-[#FF3739]"></div>
								<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
									Certificates not issued{" "}
								</h6>
							</div>
							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={"not-certificate"} />
							</h6>
						</div>
						<div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-gap-6">
							<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
								Total Registed Courses
							</h6>

							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={"total"} />
							</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const UserSection = () => {
	const navigate = useNavigate();
	return (
		<div className="tw-mt-4 tw-grid lg:tw-grid-cols-2 tw-gap-8">
			<div
				style={{
					border: "1px solid rgba(1, 52, 103, 0.30)",
					borderRadius: "12px",
				}}
				className="lg:tw-h-48 tw-p-4">
				{" "}
				<div className="tw-flex tw-justify-between tw-items-center">
					<div>
						<h5 className="tw-text-base tw-text-secondary tw-font-medium satoshi">
							Progress Tracking
						</h5>
					</div>
					<h6
						onClick={() => navigate("/reports/progress")}
						className="tw-text-sm tw-font-medium satoshi tw-text-myblue tw-underline tw-cursor-pointer">
						View
					</h6>
				</div>
				<div className="tw-flex tw-gap-5 tw-items-center tw-justify-between tw-mt-4">
					<img src={UserChart} alt="" className="" />
					<div className="tw-space-y-2">
						<div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-gap-6">
							<div className="tw-flex tw-items-center tw-gap-2">
								<div className="tw-h-4 tw-w-4 tw-bg-[#1300F2]"></div>
								<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
									Ongoing Courses
								</h6>
							</div>
							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={""} />
							</h6>
						</div>
						<div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-gap-6">
							<div className="tw-flex tw-items-center tw-gap-2">
								<div className="tw-h-4 tw-w-4 tw-bg-[#C59300]"></div>
								<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
									Locked Courses{" "}
								</h6>
							</div>
							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={"lock-out"} />
							</h6>
						</div>
						<div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-gap-6">
							<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
								Total Registered Courses
							</h6>

							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={"total"} />
							</h6>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					border: "1px solid rgba(1, 52, 103, 0.30)",
					borderRadius: "12px",
				}}
				className="lg:tw-h-48 tw-p-4">
				{" "}
				<div className="tw-flex tw-justify-between tw-items-center">
					<div>
						<h5 className="tw-text-base tw-text-secondary tw-font-medium satoshi">
							Completed
						</h5>
					</div>
					<h6 className="tw-text-sm tw-font-medium satoshi tw-text-myblue tw-underline tw-cursor-pointer">
						View
					</h6>
				</div>
				<div className="tw-flex tw-gap-5 tw-items-center tw-justify-between tw-mt-4">
					<img src={MetricChart} alt="" className="" />
					<div className="tw-space-y-2">
						<div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-gap-6">
							<div className="tw-flex tw-items-center tw-gap-2">
								<div className="tw-h-4 tw-w-4 tw-bg-[#006A0B]"></div>
								<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
									Completed{" "}
								</h6>
							</div>
							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={"completed"} />
							</h6>
						</div>
						<div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-gap-6">
							<div className="tw-flex tw-items-center tw-gap-2">
								<div className="tw-h-4 tw-w-4 tw-bg-[#FF3739]"></div>
								<h6 className="tw-text-sm tw-pt-1 satoshi tw-font-normal tw-text-secondary">
									Not completed{" "}
								</h6>
							</div>
							<h6 className="tw-text-sm satoshi tw-pt-1 tw-font-normal tw-text-secondary">
								<CourseDashTable type={"not-completed"} />
							</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reports;
