import { useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { AddCourseDetail } from "./add-course-new";

const ManageTemplate = () => {
	const { state } = useLocation(),
		[datum, setDatum] = useState(null);

	useEffect(() => {
		if (state) {
			setDatum(state);
		}
	}, [state]);
	// console.log({ datum, state });

	return (
		<div>
			<MainContainer>
				<MainHeader text="Template" small={"Template"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					<AddCourseDetail
						datum={datum ? { module: [datum] } : null}
						template="template"
					/>
				</div>
			</MainContainer>
		</div>
	);
};

export default ManageTemplate;
