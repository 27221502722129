import React from "react";
import { useCourseData } from "../../pages/dashboard";

const AssessmentTable = () => {
	const Headers = ["course name", "no of participants", "score"];
	// data = [
	//   {
	//     name: "John Doe",
	//     no: "49",
	//     score: "49",
	//   },
	//   {
	//     name: "John Doe",
	//     no: "49",
	//     score: "49",
	//   },
	//   {
	//     name: "John Doe",
	//     no: "49",
	//     score: "49",
	//   },
	//   {
	//     name: "John Doe",
	//     no: "49",
	//     score: "49",
	//   },
	//   {
	//     name: "John Doe",
	//     no: "49",
	//     score: "49",
	//   },
	//   {
	//     name: "John Doe",
	//     no: "49",
	//     score: "49",
	//   },
	//   {
	//     name: "John Doe",
	//     no: "49",
	//     score: "49",
	//   },
	// ];

	let { courses, courses2 } = useCourseData();

	if (!courses || !courses2) return <></>;

	return (
		<div>
			<div className=" tw-overflow-x-scroll">
				<table className="tw-min-w-full tw-min-h-full tw-table-auto">
					<thead>
						<tr>
							{Headers?.map(item => (
								<th className="tw-text-skyblue  tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{courses?.map((item, i) => (
							<tr
								style={{
									borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								key={i}>
								<td className="tableText ">
									{item?.lastName} {item?.firstName}
								</td>
								<td className="tableText  tw-capitalize">
									{
										courses2?.find(it => it?._id === item?.course?._id)
											?.partakers?.length
									}
								</td>
								<td className="tableText  tw-capitalize">
									{Number(item?.progress?.score || 0).toFixed(2)}%
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default AssessmentTable;
