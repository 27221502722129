import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Avatar from "../../assets/Image.svg";
import CourseLogTable from "../../components/tables/courselogtable";
import { apiCall } from "../../data/stores/authstore";
import useCourseStore from "../../data/stores/coursestore";
import { useLocation, useNavigate } from "react-router-dom";
import useUserStore from "../../data/stores/userstore";
import UserTable from "../../components/tables/userstable";
import useErrorStore from "../../data/stores/errorstore";
import { DeleteBtn, MainBtn } from "../../components/button";

export const MainStudentById = () => {
	let { getAllCourse, allCourse } = useCourseStore(),
		navigate = useNavigate(),
		{ state } = useLocation(),
		[courses, setCourses] = useState(null),
		{ data } = useUserStore(),
		[datum, setDatum] = useState(null),
		[stat, setStat] = useState(null),
		{ id } = useParams();

	useEffect(() => {
		if (!state) navigate(-1);
	}, [state, navigate]);

	useEffect(() => {
		data?.docs?.map(it => it?._id === state?._id && setDatum(it));
	}, [data, state]);

	useEffect(() => {
		if (datum) {
			let findCourses = allCourse?.docs?.filter(it => {
				let findP =
					id === "institution"
						? it?.organisation === datum?.organisation
						: it?.partakers?.find(ij => ij?._id === datum?._id);

				return findP;
			});
			setCourses(findCourses);
		}
	}, [datum, allCourse, id]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/course?pagination=not${
				id === "institution" ? `&institution=${datum?.organisation}` : ""
			}`,
			null,
			getAllCourse
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (courses && datum) {
			let progressor = [],
				lockedout = [],
				completed = [],
				certificate = [];
			for (let c = 0; c < courses.length; c++) {
				let element = courses[c];
				if (
					element?.partakers?.find(ij => ij?._id === datum?._id)?.progress
						?.status === "in-progress"
				)
					progressor?.push(element);
				if (
					element?.partakers?.find(ij => ij?._id === datum?._id)?.progress
						?.status === "completed"
				)
					completed?.push(element);
				if (
					element?.partakers?.find(ij => ij?._id === datum?._id)?.progress
						?.status === "lock-out"
				)
					lockedout?.push(element);
				if (
					element?.partakers?.find(ij => ij?._id === datum?._id)?.progress
						?.status === "lock-out"
				)
					lockedout?.push(element);
				if (
					element?.partakers?.find(ij => ij?._id === datum?._id)?.progress
						?.certificate
				)
					certificate?.push(element);
			}
			setStat({
				certificate: certificate?.length,
				progress: progressor?.length,
				locked: lockedout?.length,
				completed: completed?.length,
			});
		}
	}, [datum, courses]);

	const Arr = [
		{
			title: "Total Courses",
			value: courses?.length || "0",
		},
		{
			title: "In-progress",
			value: stat?.progress || "0",
		},
		{
			title: "Locked-Out",
			value: stat?.locked || "0",
		},
		{
			title: "Completed",
			value: stat?.completed || "0",
		},
		{
			title: "Certificates earned",
			value: stat?.certificate || "0",
		},
	];

	if (!datum) return <></>;

	return (
		<div>
			<MainContainer>
				<MainHeader
					text={id?.toUpperCase() || "Students"}
					small={`${datum?.firstName || ""}`}
				/>
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-6">
					<div className="tw-h-24 tw-flex tw-gap-2 tw-items-center tw-pl-6 tw-mt-2 tw-bg-[#0269D00F] tw-w-full tw-rounded-xl">
						<img src={datum?.image?.url || Avatar} alt="" className="tw-h-16" />
						<div className="tw-flex tw-items-center tw-gap-3">
							<h6 className="tw-text-2xl tw-font-medium satoshi tw-text-main">
								{datum?.lastName} {datum?.firstName}
							</h6>
							<EnableDisableAccount user={datum} />
						</div>
					</div>
					<div className="tw-mt-6">
						<small className="tw-text-xs tw-text-skyblue tw-font-bold satoshi">
							Course Overview
						</small>
						<div className="tw-mt-4 tw-grid lg:tw-grid-cols-5 tw-gap-4">
							{Arr.map((item, i) => (
								<div
									key={i}
									style={{
										border: "0.5px solid rgba(1, 52, 103, 0.50)",
									}}
									className="tw-py-4 tw-rounded tw-flex tw-gap-2 tw-justify-center">
									<h5 className="tw-text-[32px] tw-text-main satoshi tw-font-medium">
										{item?.value}
									</h5>
									<small className="tw-text-main tw-pt-3 tw-text-sm tw-font-medium satoshi">
										{item?.title}
									</small>
								</div>
							))}
						</div>
						{["company", "institution"]?.includes(id) && (
							<div
								style={{
									border: "1px solid rgba(1, 52, 103, 0.30)",
								}}
								className="tw-overflow-x-auto tw-rounded-2xl tw-mt-8 tw-w-full tw-p-5">
								<div className="tw-py-8">
									<small className="tw-text-xs tw-text-skyblue tw-font-bold satoshi">
										Users
									</small>
									<UserTable user={datum} />
								</div>
							</div>
						)}
						<div className="tw-mt-8">
							<small className="tw-text-xs tw-text-skyblue tw-font-bold satoshi">
								Course Log
							</small>
							<CourseLogTable courses={courses} user={datum} />
						</div>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

const StudentById = () => <MainStudentById />;

export default StudentById;

export const EnableDisableAccount = ({ user }) => {
	let [loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ updateUser } = useUserStore(),
		navigate = useNavigate(),
		handleSubmit = async e => {
			e?.preventDefault();

			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				"put",
				`/api/v1/user/manage-users/${user?._id}?type=${
					user?.adminStatusText !== "enable" ? "enable" : "disable"
				}`
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				updateUser(response);
				navigate(-1);
				return;
			}
			setLoading(false);
		};

	return (
		<>
			{user?.adminStatusText !== "enable" ? (
				<MainBtn
					onClick={handleSubmit}
					loading={loading}
					text={"Activate Account"}
				/>
			) : (
				<DeleteBtn
					loading={loading}
					onClick={handleSubmit}
					text={"Suspend Account"}
				/>
			)}
		</>
	);
};