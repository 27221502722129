import React, { useEffect, useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import List from "../../assets/list.svg";
import { Button, MainBtn, MainPaginate } from "../../components/button";
import usePlanStore from "../../data/stores/planstore";
import { apiCall } from "../../data/stores/authstore";
import { ModalComponents } from "../../components/modals/modalcontainer";
import { TextInput } from "../../components/inputs";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import useErrorStore from "../../data/stores/errorstore";

const Plan = () => {
	const Arr = [
		{
			name: "Basic",
			price: "#125",
			features: [
				"10% Discount",
				"All plans",
				"2 month access to plan after certificate earned",
				"10 Staffs",
			],
		},
		{
			name: "Professional",
			price: "#125",
			features: [
				"10% Discount",
				"All plans",
				"2 month access to plan after certificate earned",
				"10 Staffs",
			],
		},
		{
			name: "Standard",
			price: "#125",
			features: [
				"10% Discount",
				"All plans",
				"2 month access to plan after certificate earned",
				"10 Staffs",
			],
		},
	];

	let [datum, setDatum] = useState({ docs: Arr }),
		{ isFound, mainSearch, data, getPlan, addPlan, updatePlan } =
			usePlanStore(),
		{ returnErrors } = useErrorStore(),
		[isOpen, setIsOpen] = useState(null);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall("get", "/api/v1/plan", null, getPlan);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let [range] = useState(10),
		[state, setState] = useState({}),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		handleSubmit = async e => {
			e?.preventDefault();
			if (!state?.name) return toast.info("Plan name is required");
			if (!state?.discount) return toast.info("Plan discount is required");
			if (!state?.noOfPartakers)
				return toast.info("Plan maximum users' is required");
			let newState = state;
			console.log({ newState });
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				state?._id ? "put" : "post",
				`/api/v1/plan${state?._id ? `?plan=${state?._id}` : ""}`,
				newState
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				state?._id ? updatePlan(response) : addPlan(response);
				setState(null);
				setIsOpen(null);
				return;
			}
			setLoading(false);
		};

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	const handleInputKeyPress = (e, field) => {
		setState(prevRows => {
			const newRows = { ...prevRows };
			// console.log({ e: e.key });
			let emailInput = newRows?.features;
			if (e.key === "Enter" && emailInput.trim() !== "") {
				newRows[field] = Array.isArray(newRows[field])
					? [...newRows[field], emailInput.trim()]
					: [emailInput.trim()];
				newRows.features = "";
			}
			return newRows;
		});
	};

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Plans"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-6">
					<div className="tw-flex tw-justify-between tw-items-center">
						<div className="tw-flex tw-gap-4 tw-items-center">
							<h5 className="lg:tw-text-base tw-text-xs tw-text-skyblue tw-font-bold satoshi">
								All Plans
							</h5>
							{/* <SearchInput /> */}
						</div>
						<MainBtn onClick={() => setIsOpen(true)} text={"Add plan"} />
					</div>
					{/* <h2 className="tw-text-3xl tw-font-extrabold satoshi tw-text-center tw-text-secondary">
						Simple Plan for Your Company
					</h2>
					<h6 className="tw-text-center tw-font-normal tw-text-[#252525] tw-text-sm">
						You can invest in your future by studying with us whenever and{" "}
					</h6> */}
					<div className="tw-mt-10 tw-w-11/12 tw-mx-auto tw-grid lg:tw-grid-cols-3 tw-gap-6">
						{currentItems?.map((it, i) => (
							<div
								key={i}
								className="tw-bg-[#F2F8FF] tw-rounded-xl tw-border tw-border-[#0269D0] tw-p-6">
								<h2 className="tw-text-main tw-text-2xl tw-font-bold satoshi tw-capitalize">
									{it?.name}
								</h2>
								{/* <div className="tw-mt-6 tw-flex tw-gap-3">
									<div>
										{" "}
										<h3 className="tw-text-5xl tw-text-main tw-font-bold satoshi">
											{nairaSign}
											{numberWithCommas(Number(it?.amount || 0).toFixed(0))}
										</h3>
									</div>
									<s className="tw-text-3xl tw-pt-2 tw-text-main tw-font-bold tw-text-opacity-40">
										{nairaSign}225
									</s>
								</div> */}
								<div className="tw-mt-10 tw-space-y-">
									<div className="tw-flex tw-items-center tw-gap-3">
										<img src={List} alt="" className="" />
										<h6 className="tw-pt-1 tw-text-base tw-text-main tw-font-normal satoshi">
											{it?.discount}% Discount
										</h6>
									</div>
									<div className="tw-flex tw-items-center tw-gap-3">
										<img src={List} alt="" className="" />
										<h6 className="tw-pt-1 tw-text-base tw-text-main tw-font-normal satoshi">
											Maximum number of {it?.noOfPartakers} Staffs
										</h6>
									</div>
									{it?.benefits?.map((feature, f) => (
										<div key={f} className="tw-flex tw-items-center tw-gap-3">
											<img src={List} alt="" className="" />
											<h6 className="tw-pt-1 tw-text-base tw-text-main tw-font-normal satoshi">
												{feature}
											</h6>
										</div>
									))}
								</div>
								<div className="tw-mt-4">
									<MainBtn
										text={"Edit"}
										onClick={() => {
											setIsOpen(true);
											setState(it);
										}}
									/>
								</div>
							</div>
						))}
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</MainContainer>
			<ModalComponents
				show={isOpen}
				title={"Add Plan"}
				width={"tw-max-w-xl"}
				close={() => setIsOpen(null)}>
				<form className="lg:tw-w-11/12">
					<div className="tw-space-y-4">
						<TextInput
							label={"Name of Plan"}
							value={state?.name}
							onChange={textChange}
							name={"name"}
							placeholder={"Basic"}
						/>
						<TextInput
							label={"Plan Discount"}
							value={state?.discount}
							setState={e => setState({ ...state, discount: e })}
							onChange={textChange}
							name={"discount"}
							type={"tel"}
							percentage
						/>
						<TextInput
							label={"Maximum Number of User"}
							value={state?.noOfPartakers}
							setState={e => setState({ ...state, noOfPartakers: e })}
							onChange={textChange}
							name={"noOfPartakers"}
							type={"tel"}
							noFormat
						/>
						<div>
							<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
								Features
							</p>
							<div className="">
								{state?.benefits?.length > 0 && (
									<div className="tw-flex tw-items-center tw-gap-2 tw-overflow-x-auto noScroll tw-py-2 tw-px-3">
										{state?.benefits?.map((it, x) => (
											<span
												key={x}
												className="tw-text-xs tw-border tw-border-black tw-rounded-full tw-px-3 tw-py-1 tw-flex tw-items-center tw-justify-between tw-gap-3">
												{it}{" "}
												<FaTimes
													className="tw-cursor-pointer"
													onClick={() => {
														let newArr = state?.benefits?.filter(
															(j, k) => k !== x
														);
														let newItem = {
															...state,
															benefits: newArr,
														};
														setState(newItem);
														// setItemForm(prevRows => {
														// 	const newRows = [...prevRows];
														// 	newRows[index].dataArr?.splice(x, 1);
														// 	return newRows;
														// });
													}}
												/>
											</span>
										))}
									</div>
								)}
								<input
									className="tw-h-12 tw-w-full tw-border tw-border-secondary tw-pl-6 tw-rounded-lg smallText"
									type="text"
									placeholder="Press Enter to input a new Plan Features"
									value={state?.features}
									onChange={textChange}
									name="features"
									onKeyUp={event => handleInputKeyPress(event, "benefits")}
								/>
							</div>
						</div>
					</div>
					<div className="tw-mt-8 tw-flex tw-gap-5 tw-items-center">
						<Button
							onClick={handleSubmit}
							loading={loading}
							children={"Continue"}
							type="button"
						/>
					</div>
				</form>
			</ModalComponents>
		</div>
	);
};

export default Plan;
