import React, { useEffect, useState, Fragment } from "react";
import { v4 } from "uuid";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { BiLoader, BiCheckDouble, BiCheck } from "react-icons/bi";
import { toast } from "react-toastify";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { Button, MainBtn } from "../../components/button";
import {
	InstructorsTable,
	MembersTable,
} from "../../components/tables/admingrouptable";
import AddMemberModal from "../../components/modals/addmembermodal";
import SelectCourseModal from "../../components/modal/selectcourse";
import {
	AssignedTable,
	CoursesDocumentTable,
} from "../../components/tables/groupstable";
import { useGroupsStore } from "../../data/stores/loggerstore";
import useSocketStore from "../../data/stores/socketstore";
import useErrorStore from "../../data/stores/errorstore";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import Avatar from "../../assets/Category tab.svg";

const GroupDetails = () => {
	const [tab, setTab] = useState("members"),
		[modal, setModal] = useState(""),
		[item, setItem] = useState(""),
		tabs = [
			"members",
			// "instructors",
			"courses",
		],
		{ data } = useGroupsStore(),
		[mainState, setMainState] = useState(null),
		{ state } = useLocation();

	useEffect(() => {
		data?.docs?.map(it => it?._id === state?._id && setMainState(it));
	}, [data, state]);

	return (
		<div>
			<MainContainer>
				<MainHeader text={"All Groups"} small={"334 Groups"} />
				<div className="tw-bg-white tw-mt-8 tw-rounded-xl noScroll tw-p-6">
					<h5 className="tw-text-xl tw-font-bold tw-text-secondary">
						{mainState?.title}
					</h5>
					<div className="tw-mt-6 tw-flex tw-justify-between tw-items-center">
						<div className="tw-flex tw-gap-6">
							{tabs?.map(t => (
								<button
									onClick={() => {
										setTab(t);
										setItem(null);
									}}
									className={`tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize ${
										tab === t ? "tw-bg-[#D9ECFF]" : "tw-bg-transparent"
									}`}>
									{t}
								</button>
							))}
						</div>
						<div className="tw-flex tw-gap-5 tw-items-center">
							<strong className="tw-text-base tw-font-medium tw-text-[#E34033] satoshi tw-border-b tw-border-b-[#E34033]">
								Delete Group
							</strong>
							{tab === "members" ? (
								<button
									onClick={() => {
										setModal("add-member");
										setItem(null);
									}}
									className="tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize tw-bg-[#D9ECFF]">
									Add Member
								</button>
							) : tab === "courses" ? (
								<button
									onClick={() => {
										setModal("add-courses");
										setItem(null);
									}}
									className="tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize tw-bg-[#D9ECFF]">
									Assign Courses
								</button>
							) : tab === "instructors" ? (
								<button
									onClick={() => {
										setModal("add-instructor");
										setItem(null);
									}}
									className="tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize tw-bg-[#D9ECFF]">
									Add Instructor
								</button>
							) : null}
							{mainState?.chat && (
								<Button
									onClick={() => {
										setTab("chat");
										setItem(null);
									}}>
									Chat
								</Button>
							)}
							{/* <Button>Export</Button> */}
						</div>
					</div>
					<div className="tw-mt-6">
						{tab === "members" && <MembersTable datum={mainState?.members} />}
						{tab === "courses" && (
							<AssignedTable
								setTab={it => {
									setTab("document");
									setItem(it);
								}}
							/>
						)}
						{tab === "document" && <CoursesDocumentTable item={item} />}
						{tab === "chat" && <ChatSection />}
						{tab === "instructors" && (
							<InstructorsTable datum={mainState?.instructors} />
						)}
					</div>
				</div>
			</MainContainer>
			{["add-member", "add-instructor"]?.includes(modal) && (
				<AddMemberModal
					handleClose={() => setModal("")}
					title={modal === "add-member" ? "Member" : "Instructor"}
					name={modal === "add-member" ? "members" : "instructors"}
				/>
			)}
			{modal === "add-courses" && (
				<SelectCourseModal handleClose={() => setModal("")} />
			)}
			{/* {modal === "add-instructor" && (
				<AddInstructorModal handleClose={() => setModal("")} />
			)} */}
		</div>
	);
};

export default GroupDetails;

export const ChatSection = () => {
	const { state } = useLocation(),
		{ message, resetSocket, getChats } = useSocketStore(),
		[chats, setChats] = useState(null),
		[content, setContent] = useState(""),
		{ returnErrors } = useErrorStore(),
		{ data } = useGroupsStore(),
		[mainState, setMainState] = useState(null),
		{ user } = useAuthStore();

	useEffect(() => {
		data?.docs?.map(it => it?._id === state?._id && setMainState(it));
	}, [data, state]);

	useEffect(() => {
		if (mainState) {
			setChats(null);
			resetSocket();
			apiCall(
				"get",
				`/api/v1/groups/chat?chat=${mainState?.chat}`,
				null,
				setChats
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainState]);

	useEffect(() => {
		apiCall("get", "/api/v1/groups/chat/manage-chat", null, getChats);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// console.log({ message, state });

	useEffect(() => {
		if (message) {
			if (message?.chat === mainState?.chat)
				setChats({
					...chats,
					data: {
						...chats?.data,
						docs: Array.isArray(chats?.data?.docs)
							? [...chats?.data?.docs, message]
							: [message],
					},
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainState, message]);

	let handleSubmit = async e => {
		e?.preventDefault();
		let newState = {
			chat: mainState?.chat || mainState?._id,
			content,
			type: "text",
			initId: v4(),
			createdAt: new Date(),
		};
		console.log({ newState });
		if (!mainState?.chat || !content) return;

		setChats({
			...chats,
			data: {
				...chats?.data,
				docs: Array.isArray(chats?.data?.docs)
					? [...chats?.data?.docs, { ...newState, sender: user }]
					: [{ ...newState, sender: user }],
			},
		});
		setContent("");
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/groups/chat`,
			newState,
			null,
			null,
			"noToast"
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			return returnErrors(errArr);
		}
		if (errMsg) {
			return toast.error(errMsg);
		}
		if (response) {
			let newD = chats?.data?.docs;
			console.log({ response });
			console.log({ newD }, "one");
			let findIt = newD?.find(it => it?.initId === newState?.initId);
			if (findIt)
				newD = [
					...newD?.map(it =>
						it?.initId === newState?.initId ? response?.data : it
					),
				];
			else {
				newD = [...newD, response?.data];
			}

			console.log({ newD }, "two");
			setChats({
				...chats,
				data: {
					...chats?.data,
					docs: newD,
				},
			});
			return;
		}
	};

	return (
		<div className="tw-p-5 tw-bg-white tw-rounded-xl tw-h-full tw-flex tw-flex-col tw-relative">
			<h2 className="tw-text-xl tw-font-bold tw-text-secondary tw-hidden">
				Course Chat- English Language
			</h2>
			<div className="tw-mt-5 tw-h-screen tw-mb-16 tw-overflow-y-scroll noScroll tw-space-y-5">
				<div className="tw-space-y-4 tw-overflow-y-auto tw-max-h-[750px] tw-w-full">
					{chats?.data?.docs?.map((ct, m) => (
						<Fragment key={m}>
							{ct?.sender?._id === user?._id ? (
								<>
									<div className="tw-float-end  tw-flex tw-flex-col tw-w-3/4 ">
										<div className="tw-items-center tw-flex tw-gap-4">
											<div
												style={{
													border: "0.5px solid #4ABE82",
												}}
												className="tw-rounded-full tw-p-4 tw-w-full">
												<span className="tw-text-sm tw-font-normal satoshi tw-text-[#001302]">
													{ct?.content}
												</span>
											</div>
											<img
												src={ct?.sender?.image?.url || Avatar}
												alt=""
												className=""
											/>
										</div>
										<div className="tw-flex tw-justify-end tw-items-center">
											<small className="tw-text-sm tw-font-normal satoshi tw-text-[#000851]">
												{moment(ct?.createdAt).diff(moment(), "years") < 0
													? moment(ct?.createdAt).format("L hh:mm A")
													: moment(ct?.createdAt).diff(moment(), "months") < 0
													? moment(ct?.createdAt).format("DD/MM hh:mm A")
													: moment(ct?.createdAt).diff(moment(), "days") < 0
													? moment(ct?.createdAt).format("DD/MM hh:mm A")
													: moment(ct?.createdAt).format("hh:mm A")}
											</small>
											<span className="tw-ml-2">
												{ct?.sender?._id === user?._id &&
													(ct?.isDeliveredTo?.includes(user?._id) ? (
														<BiCheckDouble
															className={`fontReduce ${
																ct?.isSeenTo?.includes(user?._id)
																	? " tw-text-[#002F19]"
																	: "text-dark"
															}`}
														/>
													) : ct?.isSent ? (
														<BiCheck className="" />
													) : (
														<BiLoader className="" />
													))}
											</span>
										</div>
									</div>
								</>
							) : (
								<>
									<div className="tw-float-start tw-flex tw-flex-col tw-w-3/4 ">
										<div className="tw-flex tw-gap-4 tw-items-center ">
											<img
												src={ct?.sender?.image?.url || Avatar}
												alt=""
												className=""
											/>
											<div
												style={{
													border: "1px solid #EBEBEB",
												}}
												className="tw-rounded-xl tw-p-4 tw-w-full">
												<span className="tw-text-sm tw-font-normal satoshi tw-text-[#001302]">
													{ct?.content}
												</span>
											</div>
										</div>
									</div>
								</>
							)}
							{/* <div
								className={`tw-items-center tw-w-3/4 tw-flex tw-gap-4 ${
									ct?.sender?._id === user?._id
										? "tw-float-end"
										: "tw-float-start"
								}`}>
								<div>
									<div
										className={` tw-w-96 tw-p-5  ${
											ct?.sender?._id === user?._id
												? "tw-bg-[#F4FAFF] tw-mr-8"
												: "tw-bg-white tw-border tw-border-[#EEEEEE] tw-ml-8"
										} `}>
										<small className="tw-text-sm satoshi tw-font-normal tw-text-[#000851]">
											{ct?.content}
										</small>
									</div>
									<div className="tw-flex tw-justify-between tw-items-center">
										<div
											className={`tw-flex tw-items-center ${
												ct?.sender?._id === user?._id
													? "tw-order-1"
													: " tw-order-2"
											}`}>
											<small className="tw-text-sm tw-font-normal satoshi tw-text-[#000851]">
												{moment(ct?.createdAt).diff(moment(), "years") < 0
													? moment(ct?.createdAt).format("L hh:mm A")
													: moment(ct?.createdAt).diff(moment(), "months") < 0
													? moment(ct?.createdAt).format("DD/MM hh:mm A")
													: moment(ct?.createdAt).diff(moment(), "days") < 0
													? moment(ct?.createdAt).format("DD/MM hh:mm A")
													: moment(ct?.createdAt).format("hh:mm A")}
											</small>
											<span className="tw-ml-2">
												{ct?.sender?._id === user?._id &&
													(ct?.isDeliveredTo?.includes(user?._id) ? (
														<BiCheckDouble
															className={`fontReduce ${
																ct?.isSeenTo?.includes(user?._id)
																	? " tw-text-[#002F19]"
																	: "text-dark"
															}`}
														/>
													) : ct?.isSent ? (
														<BiCheck className="" />
													) : (
														<BiLoader className="" />
													))}
											</span>
										</div>
										<img
											src={ct?.sender?.image?.url || Avatar}
											alt=""
											className={`tw-size-8 tw-rounded-full ${
												ct?.sender?._id === user?._id
													? "tw-order-2"
													: " tw-order-1"
											}`}
										/>
									</div>
								</div>
							</div> */}
						</Fragment>
					))}
				</div>
				<div className="tw-absolute tw-w-full tw-bottom-6 tw-px-5">
					<div className="tw-w-5/6 tw-mx-auto tw-bg-[#F0F4FF] tw-rounded-lg tw-relative tw-h-12 tw-border-[0.4px] tw-border-[#FFFFFF33]">
						<>
							<input
								type="text"
								className="tw-rounded-lg tw-px-8 tw-w-[80%] tw-h-full tw-bg-transparent tw-border-[0.4px] tw-border-[#FFFFFF33]"
								value={content}
								onChange={e => setContent(e?.target?.value)}
							/>
							<div className="tw-absolute tw-right-2 tw-top-1">
								<MainBtn
									// bg={"#000851"}
									// color={"white"}
									text={"Send"}
									onClick={handleSubmit}
								/>
							</div>
						</>
					</div>
				</div>
			</div>
		</div>
	);
};
