import React, { useEffect } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import useAuthStore from "../../data/stores/authstore";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import useGenFetcher from "../../data/useGenFetcher";
import useErrorStore from "../../data/stores/errorstore";
import { Button } from "../../components/button";

const Login = () => {
	const init = {
			email: "",
			password: "",
		},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		};
	const [show, setShow] = useState(false),
		{ isAuth, login, setUserFail } = useAuthStore(),
		{ loadUser } = useGenFetcher(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isAuth) {
			navigate("/");
		}
	});
	const handleSubmit = async e => {
	
		e?.preventDefault();
		if (!state?.email || !state?.password)
			return toast.info("Please fill all required fields");
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/user/login`, { ...state });
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			login(res?.data);
			loadUser();
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				returnErrors(error);
			} else {
				toast.error(err?.response?.data?.message);
			}
			setUserFail();
		}
		setLoading(false);
	};
	return (
		<div>
			<div className="tw-w-full tw-h-screen">
				<div className="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center">
					<div>
						<h1 className="tw-text-main tw-font-extrabold satoshi tw-text-3xl">
							Welcome Back
						</h1>
						<h6 className="tw-text-sm tw-text-main satoshi tw-font-medium tw-pt-1">
							Sign in to continue learning
						</h6>
						<form onSubmit={handleSubmit} className="tw-mt-6">
							<div className="tw-space-y-4">
								<div>
									<p className="smallText">Email Address</p>
									<input
										type="text"
										name="email"
										onChange={textChange}
										className="tw-w-96 tw-h-14 tw-bg-primary myBorder tw-pl-4 tw-rounded-lg smallText"
										placeholder="email@example.com"
									/>
								</div>
								<div>
									<p className="smallText">Password</p>
									<div className="tw-relative tw-h-14 tw-w-96 myBorder tw-rounded-lg tw-bg-primary">
										<input
											type={show ? "text" : "password"}
											name="password"
											onChange={textChange}
											className="tw-w-full tw-h-full tw-bg-primary tw-pl-4 tw-rounded-lg smallText"
											placeholder="**********"
										/>
										<div
											onClick={() => setShow(!show)}
											className="tw-absolute tw-cursor-pointer tw-right-4 tw-top-5">
											{show ? <AiFillEyeInvisible /> : <AiFillEye />}
										</div>
									</div>
								</div>
							</div>
							<div className="tw-mt-4 tw-flex tw-w-96 tw-justify-between tw-items-center">
								<div className="tw-flex tw-gap-1 tw-items-center">
									<input
										type="checkbox"
										name=""
										className="tw-h-3 tw-w-3 checked:tw-bg-main"
										id=""
									/>
									<small className="smallText">Remember me</small>
								</div>
								<small className="smallText tw-cursor-pointer">
									Forgot password?
								</small>
							</div>
							{/* <button
								onClick={handleSubmit}
								className="tw-bg-main tw-w-96 tw-h-14 tw-rounded-3xl tw-mt-7 tw-text-white tw-text-base tw-font-bold satoshi">
								Sign in
							</button> */}
							<Button
								type="submit"
								onClick={handleSubmit}
								loading={loading}
								children="Sign In"
								// eslint-disable-next-line react/style-prop-object
								css="tw-bg-main tw-w-96 tw-h-14 tw-rounded-3xl tw-mt-7 tw-text-white tw-text-base tw-font-bold satoshi tw-flex tw-items-center tw-justify-center"
							/>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
