import React, { useState, useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import img from "../../assets/details.png";
import { DeleteBtn, MainBtn } from "../../components/button";
import Modulestab from "../../components/tabs/modulestab";
import CertificatesTab from "../../components/tabs/certtab";
import AttractionTab from "../../components/tabs/atractiontab";
import ParticipantTab from "../../components/tabs/participantstab";
import ResultTab from "../../components/tabs/resulttab";
import DeleteModal from "../../components/modals/deletemodal";
import { useNavigate, useLocation } from "react-router-dom";
import useCourseStore from "../../data/stores/coursestore";
import { apiCall } from "../../data/stores/authstore";
import { toast } from "react-toastify";
import useErrorStore from "../../data/stores/errorstore";
import DOMPurify from "dompurify";

export const createMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

const CourseDetails = () => {
  const [tab, setTab] = useState("modules"),
    [modal, setModal] = useState("");

  const navigate = useNavigate(),
    { state } = useLocation(),
    [datum, setDatum] = useState(null),
    { data, deleteCourse } = useCourseStore(),
    [isDeleted, setIsDeleted] = useState(null),
    [loading, setLoading] = useState(null),
    { returnErrors } = useErrorStore();
  let handleSubmit = async (e, se) => {
    e?.preventDefault();
    setLoading(true);
    let { response, errArr, errMsg } = await apiCall(
      "delete",
      `/api/v1/course/${isDeleted?._id}`,
      isDeleted
    );
    // console.log({ response, errArr, errMsg });
    if (errArr) {
      setLoading(false);
      return returnErrors(errArr);
    }
    if (errMsg) {
      setLoading(false);
      return toast.error(errMsg);
    }
    setLoading(false);
    if (response) {
      if (se) se();
      deleteCourse(isDeleted);
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    data?.docs?.map((it) => it?._id === state?._id && setDatum(it));
  }, [data, state]);

  const Arr1 = [
      {
        title: "No. of Modules",
        value: datum?.module?.length || "0",
      },
      {
        title: "No. of Attraction",
        value: "0",
      },
      {
        title: "No. of Participant",
        value: datum?.partakers?.length || "0",
      },
      {
        title: "Certificates Earned",
        value: "0",
      },
    ],
    tabs = [
      {
        name: "Modules",
        tab: "modules",
      },
      {
        name: "Certificates Earned",
        tab: "certificates",
      },
      // {
      // 	name: "Attraction",
      // 	tab: "attraction",
      // },
      {
        name: "Participants",
        tab: "participants",
      },
      {
        name: "Results",
        tab: "results",
      },
    ];

  if (!state) navigate(-1);

  if (!datum) return;

  return (
		<div>
			<MainContainer>
				<MainHeader text={"Course"} small={datum?.title || "Details"} />
				<div className="tw-w-full tw-mt-5 tw-bg-white tw-rounded-3xl tw-p-4">
					<div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
						<div className="lg:tw-w-[60%] lg:tw-flex-none">
							<img
								src={
									datum?.image?.url ||
									datum?.module?.[0]?.image?.url ||
									datum?.module?.[0]?.section?.[0]?.image?.url ||
									img
								}
								alt=""
								className="tw-rounded-t-2xl"
							/>
							<div className="tw-mt-6">
								<h4 className="tw-text-2xl tw-font-bold tw-text-secondary satoshi">
									{datum?.title}
								</h4>
								<div
									style={{
										borderRadius: "8px",
										border: "0.5px solid rgba(37, 37, 37, 0.50)",
									}}
									className="tw-mt-4 tw-p-3 tw-w-full tw-grid tw-grid-cols-4 tw-gap-4">
									{Arr1?.map((item, i) => (
										<div key={i} className="">
											<h5 className="tw-text-main tw-text-xs tw-font-normal satoshi tw-text-opacity-70">
												{item?.title}
											</h5>
											<h4 className="tw-text-base tw-font-medium tw-text-main satoshi tw-underline">
												{item?.value}
											</h4>
										</div>
									))}
								</div>
								{/* <p className="tw-text-xs satoshi tw-font-normal tw-pt-5 tw-text-main">
									{datum?.description}
								</p> */}
								{ContentWriteup(
									datum?.description,
									"tw-text-xs satoshi tw-font-normal tw-pt-5 tw-text-main"
								)}

								<div className="tw-mt-14 tw-flex tw-gap-5">
									<MainBtn
										onClick={() =>
											navigate(
												`/courses/add-course?course=${datum?._id}&type=course`,
												{ state: datum }
											)
										}
										text={"Edit Course"}
									/>
									<DeleteBtn
										onClick={() => {
											setIsDeleted(datum);
											setModal("delete");
										}}
										text={"Delete Course"}
									/>
								</div>
							</div>
						</div>
						<div
							style={{
								border: "0.5px solid rgba(37, 37, 37, 0.50)",
								borderRadius: "10px",
							}}
							className="lg:tw-w-[40%] tw-min-h-full tw-overflow-y-scroll noScroll">
							<div
								style={{
									borderBottom: "0.5px solid rgba(37, 37, 37, 0.50)",
								}}
								className="tw-p-2 tw-grid tw-grid-cols-3 tw-gap-1">
								{tabs?.map((item, i) => (
									<div
										key={i}
										onClick={() => setTab(item?.tab)}
										className={`${
											tab === item.tab
												? "tw-bg-[#0269D0] tw-text-white tw-transition-all tw-ease-in-out tw-duration-300"
												: "tw-bg-[#0269D01A] tw-text-[#0269D0]"
										} tw-p-1 tw-rounded-3xl tw-whitespace-nowrap lg:tw-text-xs tw-text-[8px] tw-text-center tw-cursor-pointer satoshi tw-font-medium satoshi`}>
										{item?.name}
									</div>
								))}
							</div>
							<div className="tw-w-full tw-p-6">
								{tab === "modules" && <Modulestab datum={datum} />}
								{tab === "certificates" && <CertificatesTab datum={datum} />}
								{tab === "attraction" && <AttractionTab datum={datum} />}
								{tab === "participants" && (
									<ParticipantTab datum={datum} tab={tab} />
								)}
								{tab === "results" && <ResultTab datum={datum} />}
							</div>
						</div>
					</div>
				</div>
			</MainContainer>
			{modal === "delete" && (
				<DeleteModal
					handleCloseModal={() => {
						setModal("");
						setIsDeleted(null);
					}}
					loading={loading}
					onClick={handleSubmit}
					title="Course"
				/>
			)}
		</div>
	);
};

export default CourseDetails;

export const Overview = ({ datum, activeSection, tab }) => {
  return (
    <>
      <div className="tw-space-y-4">
        {tab === "transcript" ? (
          <>{ContentWriteup(activeSection?.transcript)}</>
        ) : (
          ContentWriteup(activeSection?.description || datum?.description)
        )}
      </div>
    </>
  );
};

export const ContentWriteup = (content, cls, span) => {
  let Tag = span || "div";
  return (
    <>
      <Tag
        className={cls || "tw-text-xs tw-text-main satoshi"}
        dangerouslySetInnerHTML={createMarkup(content || "")}
      />
    </>
  );
};
