import React, { useEffect, useState } from "react";
import { MainPaginate, Tablebtn } from "../button";
import { useNavigate, useParams } from "react-router-dom";
import useUserStore from "../../data/stores/userstore";
import useCourseStore from "../../data/stores/coursestore";
import { apiCall } from "../../data/stores/authstore";
import { UserCourseTable } from "../../pages/dashboard";
import moment from "moment";
import { UsersArr } from "../../pages/users";

const UserTable = ({ user }) => {
  const navigate = useNavigate(),
    { id } = useParams();
  const Headers = [
      "name",
      "User type",
      "date registered",
      "no. of courses",
      user ? "" : "actions",
    ],
    data1 = [
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
    ];

  let [datum, setDatum] = useState({ docs: data1 }),
    { isFound, mainSearch, data, getUsers } = useUserStore(),
    { getAllCourse } = useCourseStore();

  useEffect(() => {
    if (isFound) setDatum(mainSearch);
    else setDatum(data);
  }, [data, isFound, mainSearch]);

  useEffect(() => {
    apiCall(
      "get",
      `/api/v1/user/manage-users?privilege=${user ? "all" : id || "staff"}${
        user ? `&user=${user?.organisation}` : ""
      }`,
      null,
      getUsers
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    apiCall("get", `/api/v1/course?pagination=not`, null, getAllCourse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [itemOffset, setItemOffset] = useState(0);
  let [range] = useState(10);
  const endOffset = itemOffset + range;

  // if (!datum && !data) return <PageLoader />;
  if (!datum) return;

  const currentItems = datum?.docs?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(datum?.docs?.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % datum?.docs?.length;
    setItemOffset(newOffset);
  };

  return (
    <div className=" tw-overflow-x-auto">
      <table className="tw-min-w-full tw-min-h-full tw-table-auto">
        <thead>
          <tr>
            {Headers?.filter((it) => it)?.map((item) => (
              <th className="tw-text-skyblue tw-p-3 tw-font-medium tw-text-left satoshi tw-text-sm tw-capitalize">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((item, i) => (
            <tr
              style={{
                borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
              }}
              key={i}
            >
              <td className="tableText">
                {item?.lastName} {item?.firstName}
              </td>
              <td className="tableText tw-capitalize">{item?.privilege}</td>
              <td className="tableText">
                {moment(item?.createdAt).format("DD MMMM, YYYY")}
              </td>
              <td className="tableText">
                <UserCourseTable type={"length"} datum={item} />
              </td>
              <td>
                {!user && (
                  <div className="tw-ml-1">
                    <Tablebtn
                      onClick={() =>
                        navigate(
                          UsersArr?.map((it) => it?.path)?.includes(id)
                            ? `${item?.firstName || i}`
                            : `/student/${item?.firstName || i}`,
                          {
                            state: item,
                          }
                        )
                      }
                      text={"View"}
                    />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pageCount > 1 && (
        <MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
      )}
    </div>
  );
};

export default UserTable;
