import React, { useEffect, useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Comp from "../../assets/comp.svg";
import Dropout from "../../assets/dropout.svg";
import CompletionTable from "../../components/tables/completiontable";
import Total from "../../assets/totalcourse.svg";
import Ongoing from "../../assets/ongoing.svg";
import { MainBtn } from "../../components/button";
import { CourseDashTable, useCourseData } from "../dashboard";

const CompletionRate = () => {
	const [tab, setTab] = useState("completion");
	const Arr = [
		{
			title: "Completed Courses",
			icon: Comp,
			value: <CourseDashTable type={"completed"} />,
			bg: "#006A0B14",
		},
		{
			title: "Dropout",
			icon: Dropout,
			value: <CourseDashTable type={"dropout"} />,
			bg: "#FF373914",
		},
	];
	const Arr2 = [
		{
			name: "Most Taken Course",
			icon: Total,
			value: <CourseDashTable type={"most-taken"} />,
			bg: "#006A0B14",
		},
		{
			name: "Average Taken Course",
			icon: Ongoing,
			value: <CourseDashTable type={"average-taken"} />,
			bg: "#0269D014",
		},
		{
			name: "Lowest Taken Course",
			icon: Ongoing,
			value: <CourseDashTable type={"lowest-taken"} />,
			bg: "#FF373914",
		},
	];
	return (
		<div>
			<MainContainer>
				<MainHeader text={"Reports"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					<h1 className="tw-text-xl satoshi tw-font-bold tw-text-secondary">
						Overview
					</h1>
					<div className="tw-flex tw-justify-between tw-flex-col lg:tw-flex-row tw-items-center">
						<div className="tw-mt-6 tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
							<div
								onClick={() => setTab("completion")}
								className={`${
									tab === "completion" && "tw-bg-[#D9ECFF]"
								} tw-h-10 tw-cursor-pointer tw-rounded-3xl tw-px-5 tw-flex tw-justify-center tw-items-center`}>
								<small className="tw-text-base satoshi tw-font-medium tw-text-[#0269D0]">
									Completion and Dropout rate
								</small>
							</div>
							<div
								onClick={() => setTab("metric")}
								className={`${
									tab === "metric" && "tw-bg-[#D9ECFF]"
								} tw-h-10 tw-cursor-pointer tw-rounded-3xl tw-px-5 tw-flex tw-justify-center tw-items-center`}>
								<small className="tw-text-base satoshi tw-font-medium tw-text-[#0269D0]">
									Content Metric
								</small>
							</div>
						</div>
						<div className="tw-flex tw-gap-4">
							<MainBtn text={"Filter"} />
							<MainBtn text={"Export"} />
						</div>
					</div>

					{tab === "completion" && <Completed Arr={Arr} />}
					{tab === "metric" && <ContentMetric Arr2={Arr2} />}
				</div>
			</MainContainer>
		</div>
	);
};
const Completed = ({ Arr }) => {
	return (
		<>
			<div
				style={{
					border: "1px solid rgba(1, 52, 103, 0.30)",
					borderRadius: "12px",
				}}
				className="tw-mt-8 lg:tw-h-40 tw-py-6 lg:tw-py-0 tw-px-6 tw-grid lg:tw-grid-cols-2 tw-gap-6 tw-items-center">
				{Arr?.map((item, i) => (
					<div
						key={i}
						style={{
							background: item?.bg,
						}}
						className="tw-h-24 tw-rounded tw-px-5 tw-flex tw-gap-6 tw-items-center">
						<div className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-shadow-md">
							{" "}
							<img src={item?.icon} alt="" className="" />
						</div>
						<div>
							<h5 className="tw-text-sm tw-font-medium satoshi tw-capitalize tw-text-main">
								{item?.title}
							</h5>
							<h2 className="tw-text-3xl tw-font-medium tw-text-main satoshi">
								{item?.value}
							</h2>
						</div>
					</div>
				))}
			</div>
			<div className="tw-mt-10">
				<CompletionTable />
			</div>
		</>
	);
};

const ContentMetric = ({ Arr2 }) => {
	const Arr1 = [
		{
			name: "Human Resource Management",
			value: "89",
		},
		{
			name: "Human Resource Management",
			value: "89",
		},
		{
			name: "Human Resource Management",
			value: "89",
		},
		{
			name: "Human Resource Management",
			value: "89",
		},
		{
			name: "Human Resource Management",
			value: "89",
		},
		{
			name: "Human Resource Management",
			value: "89",
		},
		{
			name: "Human Resource Management",
			value: "89",
		},
	];
	let { courses2 } = useCourseData(),
		[Arr, setArr] = useState(Arr1);

	useEffect(() => {
		if (courses2) {
			let newDoc = courses2,
				newArr = [];

			for (let n = 0; n < newDoc.length; n++) {
				let element = newDoc[n];
				newArr?.push({
					name: element?.title,
					value: element?.partakers?.length || 0,
				});
			}
			setArr(newArr);
		}
	}, [courses2]);

	return (
		<>
			<div
				style={{
					border: "1px solid rgba(1, 52, 103, 0.30)",
					borderRadius: "12px",
				}}
				className="tw-mt-8 lg:tw-h-40 tw-py-6 lg:tw-py-0 tw-px-6 tw-grid lg:tw-grid-cols-3 tw-gap-4 tw-items-center">
				{Arr2?.map((item, i) => (
					<div
						key={i}
						style={{
							background: item?.bg,
						}}
						className="tw-h-24 tw-rounded tw-px-5 tw-flex tw-gap-6 tw-items-center">
						<div className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-shadow-md">
							{" "}
							<img src={item?.icon} alt="" className="" />
						</div>
						<div>
							<h5 className="tw-text-sm tw-font-medium satoshi tw-capitalize tw-text-main">
								{item?.name}
							</h5>
							<h2 className="tw-text-3xl tw-font-medium tw-text-main satoshi">
								{item?.value}
							</h2>
						</div>
					</div>
				))}
			</div>
			<div className="tw-mt-10 tw-w-3/4">
				<div className="tw-flex tw-justify-between tw-items-center">
					<h6 className="tw-text-base tw-text-secondary tw-font-medium satoshi">
						Courses
					</h6>
					<h6 className="tw-text-base tw-text-secondary tw-font-medium satoshi">
						No of Participants
					</h6>
				</div>
				<div className="tw-mt-4">
					{Arr?.map(item => (
						<div
							style={{
								borderBottom: "0.5px solid rgba(1, 52, 103, 0.30)",
							}}
							className="tw-pb-1 tw-flex tw-justify-between tw-px-4 tw-items-center">
							<h6 className="tw-text-sm tw-pt-1 tw-text-main tw-fontnormal satoshi">
								{item?.name}
							</h6>
							<h6 className="tw-text-sm tw-pt-1 tw-text-main tw-fontnormal satoshi">
								{item?.value}
							</h6>
						</div>
					))}
				</div>
			</div>
		</>
	);
};
export default CompletionRate;
