import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageRender from "./PageRender";
import Index from "./pages/index";
import useAuthStore from "./data/stores/authstore";
import Login from "./screens/home";
import Sidebar from "./components/sidebar";
import useErrorStore from "./data/stores/errorstore";
import { ModalComponents } from "./components/modals/modalcontainer";

const Routers = () => {
	// const location = useLocation(),
	let { isAuth } = useAuthStore(),
		{ error, clearErrors } = useErrorStore();
	// console.log(location);

	return (
		<>
			<ToastContainer position="bottom-left" />

			<Sidebar>
				<Routes>
					<Route path="/" element={isAuth ? <Index /> : <Login />} />
					<Route path="/:page" element={<PageRender />} />
					<Route path="/:page/:id" element={<PageRender />} />
					<Route path="/:page/:id/:step" element={<PageRender />} />
				</Routes>
			</Sidebar>
			<ModalComponents
				show={error?.length > 0}
				title="Error"
				width={"tw-max-w-md"}
				close={() => clearErrors()}>
				<div className="downH2 tw-flex tw-flex-col">
					{error?.map((item, i) => (
						<p key={i} className="tw-fw-bold Lexend tw-text-center tw-w-100">
							<span className="fontInherit tw-me-2">
								{error?.length !== 1 && <>{i + 1}.</>}
							</span>{" "}
							{item?.message || item || null}
						</p>
					))}
					<button
						style={{
							boxShadow: "0px 5px 7.3px 0px rgba(5, 76, 166, 0.20)",
						}}
						onClick={() => clearErrors()}
						className="tw-h-10 tw-px-4 md:tw-px-8 tw-bg-skyblue tw-rounded-3xl tw-text-base satoshi tw-font-medium tw-text-white tw-flex tw-items-center tw-capitalize tw-justify-center tw-mx-auto">
						close
					</button>
				</div>
			</ModalComponents>
		</>
	);
};

export default Routers;
