import React, { useEffect, useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { CouponUserTable } from "../../components/tables/couponstable";
import { useLocation, useNavigate } from "react-router-dom";
import { useCouponStore } from "../../data/stores/loggerstore";
import useErrorStore from "../../data/stores/errorstore";
import { apiCall } from "../../data/stores/authstore";
import { toast } from "react-toastify";
import { DeleteBtn, MainBtn } from "../../components/button";
import DeleteModal from "../../components/modals/deletemodal";
import AddCouponModal from "../../components/modals/add-coupon";

const CouponDetail = () => {
	const navigate = useNavigate(),
		{ state } = useLocation(),
		[datum, setDatum] = useState(null),
		{ data, deactivated } = useCouponStore(),
		[noOfUsers, setNoOfUsers] = useState(0),
		[isToDeactivate, setIsToDeactivate] = useState(null),
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		handleSubmit = async (e, se) => {
			e?.preventDefault();
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				"put",
				`/api/v1/course/manage-coupon?coupon=${datum?._id}`,
				{ status: "deactivated" }
			);
			// console.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				if (se) se();
				navigate(-1);
				return;
			}
			setLoading(false);
		};

	useEffect(() => {
		let mainD = data;
		if (state?.status === "deactivated") mainD = deactivated;
		mainD?.docs?.map(it => it?._id === state?._id && setDatum(it));
	}, [data, state, deactivated]);

	if (!state) navigate(-1);

	if (!datum) return;

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Coupon"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					<div
						style={{
							border: "1px solid #0134674D",
						}}
						className="tw-h-32 tw-w-full tw-px-6 tw-flex tw-items-center tw-justify-between">
						<div className="tw-flex tw-gap-10">
							<div>
								<h6 className="tw-text-sm tw-font-medium tw-text-secondary">
									Coupon Code
								</h6>
								<h2 className="tw-text-xl tw-mt-2 tw-font-bold tw-text-secondary">
									{datum?.code}
								</h2>
							</div>
							<div>
								<h6 className="tw-text-sm tw-font-medium tw-text-secondary">
									Number of User
								</h6>
								<h2 className="tw-text-xl tw-mt-2 tw-font-bold tw-text-secondary">
									{noOfUsers}
								</h2>
							</div>
						</div>
						{datum?.status === "active" && (
							<div className="tw-flex tw-gap-5 tw-items-center">
								<DeleteBtn
									onClick={() => setIsToDeactivate("deactivate")}
									text={"Deactivate"}
								/>
								<MainBtn
									onClick={() => {
										setIsToDeactivate("add");
									}}
									text={"Edit"}
								/>
							</div>
						)}
					</div>
					<div className="tw-mt-7">
						<CouponUserTable datum={datum} setNoOfUsers={setNoOfUsers} />
					</div>
				</div>
			</MainContainer>
			{isToDeactivate === "deactivate" && (
				<DeleteModal
					handleCloseModal={() => {
						setIsToDeactivate(null);
					}}
					loading={loading}
					onClick={handleSubmit}
					title="Coupon"
					usecase="deactivate"
				/>
			)}
			{isToDeactivate === "add" && (
				<AddCouponModal
					handleClose={() => setIsToDeactivate(null)}
					datum={datum}
				/>
			)}
		</div>
	);
};
export default CouponDetail;
