import React from "react";
import { useCourseData } from "../../pages/dashboard";

const CerticationTable = () => {
	const Headers = ["name", "course name", "status"];
	// data = [
	//   {
	//     name: "John Doe",
	//     course: "Leadership",
	//     status: "Issued",
	//   },
	//   {
	//     name: "John Doe",
	//     course: "Leadership",
	//     status: "Not Issued",
	//   },
	//   {
	//     name: "John Doe",
	//     course: "Leadership",
	//     status: "Issued",
	//   },
	//   {
	//     name: "John Doe",
	//     course: "Leadership",
	//     status: "Not Issued",
	//   },
	//   {
	//     name: "John Doe",
	//     course: "Leadership",
	//     status: "Issued",
	//   },
	//   {
	//     name: "John Doe",
	//     course: "Leadership",
	//     status: "Not Issued",
	//   },
	//   {
	//     name: "John Doe",
	//     course: "Leadership",
	//     status: "Issued",
	//   },
	//   {
	//     name: "John Doe",
	//     course: "Leadership",
	//     status: "Not Issued",
	//   },
	// ];

	let { courses } = useCourseData();

	if (!courses) return <></>;

	return (
		<div>
			<div>
				<div className=" tw-overflow-x-scroll">
					<table className="tw-min-w-full tw-min-h-full tw-table-auto">
						<thead>
							<tr>
								{Headers?.map(item => (
									<th className="tw-text-skyblue tw-text-left tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
										{item}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{courses?.map((item, i) => (
								<tr
									style={{
										borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
									}}
									key={i}>
									<td className="tableText ">
										{item?.lastName} {item?.firstName}
									</td>
									<td className="tableText  tw-capitalize">
										{item?.course?.title}
									</td>
									<td
										className={`tw-text-sm satoshi tw-p-3 tw-font-normal tw-capitalize ${
											item?.progress?.certificate
												? "tw-text-[#0B6C25]"
												: "tw-text-[#E34033]"
										}`}>
										{item?.progress?.certificate ? "Issued" : "Not Issued"}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default CerticationTable;
